import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import PartnerTypesForm from '../../components/forms/partnerTypeF';
import PartnerTypesTable from '../../components/tables/partnerTypeT';
import { addPartnerType as addPartnerTypeService, getPartnerTypeById, updatePartnerType, deletePartnerType, getPartnerType } from '../../services/partnerTypeS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddPartnerTypes = () => {
  const [partnertypes, setPartnerTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPartnerTypes, setNewPartnerTypes] = useState({
    typeID: '',
    typeName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredPartnerTypes, setFilteredPartnerTypes] = useState(partnertypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedPartnerTypesID, setSelectedPartnerTypesID] = useState(null);

  useEffect(() => {
    const fetchedpartnertypes = async () => {
      try {
        const fetchedPartnerTypes = await getPartnerType();
        setPartnerTypes(fetchedPartnerTypes);
        setFilteredPartnerTypes(fetchedPartnerTypes);
      } catch (error) {
        console.error('Error fetching partnertypes:', error.response.data);
      }
    };

    fetchedpartnertypes();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPartnerTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewPartnerTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewPartnerTypes = async () => {
    try {
      const partnertypesPayload = {
        typeID: String(newPartnerTypes.typeID), // Ensure PartnerTypesID is a string
        typeName: newPartnerTypes.typeName,
        notes: newPartnerTypes.notes,
      };
  
     await addPartnerTypeService(partnertypesPayload);
     setUpdate(prev=>!prev)
     setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding partnertypes:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add partnertypes: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddPartnerTypesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewPartnerTypes({
      typeID: '',
      typeName: '',
      notes: '',
    });
  };

  const openEditPartnerTypesModal = async (typeID) => {
    try {
      const fetchedPartnerTypes = await getPartnerTypeById(String(typeID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedPartnerTypesID(typeID);
      setNewPartnerTypes({
        ...fetchedPartnerTypes,
      });
    } catch (error) {
      console.error(`Error fetching partnertypes with ID ${typeID}:`, error.response.data);
    }
  };

  const updateExistingPartnerTypes = async () => {
    try {
      const partnertypesPayload = { ...newPartnerTypes };
      await updatePartnerType(selectedPartnerTypesID, partnertypesPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating partnertypes with ID ${selectedPartnerTypesID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update partnertypes: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingPartnerTypes = async (typeID) => {
    try {
      await deletePartnerType(typeID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting partnertypes with ID ${typeID}:`, error.response.data);
      alert(`Failed to delete partnertypes: ${error.response.data.title}`);
    }
  };

  const closeAddPartnerTypesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deletePartnerTypeHandler = (typeID) => {
    if (window.confirm(`Are you sure you want to delete partnertypes with ID ${typeID}?`)) {
      deleteExistingPartnerTypes(typeID);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterPartnerTypes(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterPartnerTypes(searchTerm, value);
  };

  const filterPartnerTypes = (searchTerm, column) => {
    const filtered = partnertypes.filter((partnerType) =>
      partnerType[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredPartnerTypes(filtered);
  };


  return (
   <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Partner Types</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddPartnerTypesModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
         Add Type
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {partnertypes.length > 0 ? (
                Object.keys(partnertypes[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <PartnerTypesTable
        partnertypes={filteredPartnerTypes}
        openEditModal={openEditPartnerTypesModal}
        deletePartnerTypes={deletePartnerTypeHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddPartnerTypesModal}
      contentLabel={editMode ? "Edit Partner Types" : "Add Partner Types"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Partner Types" : "Add Partner Types"}
      </h2>
      <PartnerTypesForm
        formValues={newPartnerTypes}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingPartnerTypes : addNewPartnerTypes}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddPartnerTypesModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddPartnerTypes;
