// src/utils/pdfFormatting.js

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

// Company Details
const companyDetails = {
  name: "President's Award - Kenya",
  address: "15 Elgon Road, opposite the Kadhi's Court in Upper Hill, Nairobi",
  email: "info@presidentsaward.or.ke",
  phone: "0722 714 122, 0787 419 325",
};

// Helper function to convert image URL to Base64
const getImageBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = () => reject(null);
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

// Function to calculate dynamic column widths with a maximum cap
const calculateColumnWidths = (doc, headers, data) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 10;
  const usableWidth = pageWidth - 2 * margin;
  const maxColumnWidth = usableWidth / headers.length; // Simple division for equal widths

  const columnWidths = {};
  headers.forEach((header, index) => {
    columnWidths[index] = { cellWidth: 'wrap' }; // Enable text wrapping
  });

  return columnWidths;
};

// Main function to format PDF report
export const formatPDFReport = async (title, data, headers) => {
  const doc = new jsPDF({ orientation: 'landscape' });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 10;

  // Load logo and add to PDF
  const logoUrl = `${process.env.PUBLIC_URL}/assets/newlogo.png`;
  const logoBase64 = await getImageBase64(logoUrl);

  if (logoBase64) {
    const logoHeight = 20; // Fixed height for the logo
    const logoWidth = pageWidth - 2 * margin; // Logo width spans the entire page width minus margins
    doc.addImage(logoBase64, 'PNG', margin, margin, logoWidth, logoHeight);
  } else {
    console.error('Failed to load logo image.');
  }

  // Add company details with reduced spacing and bold text
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  const textX = pageWidth / 2; // Center the text
  const lineSpacing = 6;
  let currentY = 35; // Adjusted to start below the logo

  doc.text(companyDetails.name, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(companyDetails.address, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(`Email: ${companyDetails.email}`, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(`Phone: ${companyDetails.phone}`, textX, currentY, { align: 'center' });
  currentY += lineSpacing;

  // Draw a bolder line after the company details
  doc.setLineWidth(0.5);
  doc.line(margin, currentY, pageWidth - margin, currentY);

  // Add report title
  doc.setFontSize(14);
  doc.text(title, textX, currentY + 10, { align: 'center' });

  // Prepare table data using headers
  const tableHeaders = headers.map(header => header.Header);
  const tableData = data.map(row => headers.map(header => row[header.accessor] || ''));

  // Calculate dynamic column widths based on content with wrapping
  const columnWidths = calculateColumnWidths(doc, headers, tableData);

  // Add table with customized styles
  autoTable(doc, {
    startY: currentY + 20,
    head: [tableHeaders],
    body: tableData,
    columnStyles: columnWidths,
    styles: {
      cellWidth: 'wrap', // Ensure text wraps within cells
      overflow: 'linebreak',
      fontSize: 8,
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: 20,
      fontStyle: 'bold',
    },
    bodyStyles: {
      textColor: 20,
    },
    alternateRowStyles: {
      fillColor: [240, 240, 240],
    },
    didDrawPage: function (data) {
      const footerY = pageHeight - 10;
      doc.setFontSize(8);
      doc.text(`Page ${doc.internal.getNumberOfPages()}`, pageWidth / 2, footerY, { align: 'center' });
    },
    margin: { top: 30, bottom: 30, left: margin, right: margin },
    pageBreak: 'auto',
  });

  // Return the PDF as a blob
  return doc.output('blob');
};

// Function to save PDF directly (for export)
export const savePDFReport = async (title, data, headers) => {
  const doc = new jsPDF({ orientation: 'landscape' });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 10;

  // Load logo and add to PDF
  const logoUrl = `${process.env.PUBLIC_URL}/assets/newlogo.png`;
  const logoBase64 = await getImageBase64(logoUrl);

  if (logoBase64) {
    const logoHeight = 20; // Fixed height for the logo
    const logoWidth = pageWidth - 2 * margin; // Logo width spans the entire page width minus margins
    doc.addImage(logoBase64, 'PNG', margin, margin, logoWidth, logoHeight);
  } else {
    console.error('Failed to load logo image.');
  }

  // Add company details with reduced spacing and bold text
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  const textX = pageWidth / 2; // Center the text
  const lineSpacing = 6;
  let currentY = 35; // Adjusted to start below the logo

  doc.text(companyDetails.name, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(companyDetails.address, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(`Email: ${companyDetails.email}`, textX, currentY, { align: 'center' });
  currentY += lineSpacing;
  doc.text(`Phone: ${companyDetails.phone}`, textX, currentY, { align: 'center' });
  currentY += lineSpacing;

  // Draw a bolder line after the company details
  doc.setLineWidth(0.5);
  doc.line(margin, currentY, pageWidth - margin, currentY);

  // Add report title
  doc.setFontSize(14);
  doc.text(title, textX, currentY + 10, { align: 'center' });

  // Prepare table data using headers
  const tableHeaders = headers.map(header => header.Header);
  const tableData = data.map(row => headers.map(header => row[header.accessor] || ''));

  // Calculate dynamic column widths based on content with wrapping
  const columnWidths = calculateColumnWidths(doc, headers, tableData);

  // Add table with customized styles
  autoTable(doc, {
    startY: currentY + 20,
    head: [tableHeaders],
    body: tableData,
    columnStyles: columnWidths,
    styles: {
      cellWidth: 'wrap', // Ensure text wraps within cells
      overflow: 'linebreak',
      fontSize: 8,
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: 20,
      fontStyle: 'bold',
    },
    bodyStyles: {
      textColor: 20,
    },
    alternateRowStyles: {
      fillColor: [240, 240, 240],
    },
    didDrawPage: function (data) {
      const footerY = pageHeight - 10;
      doc.setFontSize(8);
      doc.text(`Page ${doc.internal.getNumberOfPages()}`, pageWidth / 2, footerY, { align: 'center' });
    },
    margin: { top: 30, bottom: 30, left: margin, right: margin },
    pageBreak: 'auto',
  });

  // Save the PDF
  doc.save(`${title}.pdf`);
};
