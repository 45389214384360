import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Training ID', accessor: 'trainingID' },
  { Header: 'Training Name', accessor: 'trainingName' },
  { Header: 'Institution Name', accessor: 'institutionName' },
  { Header: 'venue', accessor: 'venue' },
  { Header: 'Date', accessor: 'date' },
  { Header: 'Categories', accessor: 'categories' },
  { Header: 'Sub-County', accessor: 'subCounty' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Coordinator', accessor: 'coordinator' },
  { Header: 'Training Level', accessor: 'trainingLevel' },
  { Header: 'Training Type', accessor: 'trainingType' },
  { Header: 'Notes', accessor: 'notes' },
];

const TrainingTable = ({ trainings, openEditModal, deleteTraining }) => {
  const renderRowActions = ({ trainingID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(trainingID)} onDelete={()=>deleteTraining(trainingID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={trainings}
      renderRowActions={renderRowActions}
    />
  );
};

export default TrainingTable;
