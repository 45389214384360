import apiClient from '../apiClient';
import { formatDate } from './formatDate';

const TRAININGS_API_URL = '/api/Training';

export const fetchTrainingsData = async () => {
  try {
    const response = await apiClient.get(TRAININGS_API_URL);
    const formattedData = response.data.map(item => ({
      ...item,
      date: formatDate(item.date),
    }));
    return formattedData;
  } catch (error) {
    console.error('Error fetching trainings data:', error);
    throw error;
  }
};

export const trainingsHeaders = [
  { Header: 'Training ID', accessor: 'trainingID' },
  { Header: 'Training Name', accessor: 'trainingName' },
  { Header: 'Institution Name', accessor: 'institutionName' },
  { Header: 'Venue', accessor: 'venue' },
  { Header: 'Date', accessor: 'date' },
  { Header: 'Categories', accessor: 'categories' },
  { Header: 'Sub-County', accessor: 'subCounty' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Coordinator', accessor: 'coordinator' },
  { Header: 'Training Level', accessor: 'trainingLevel' },
  { Header: 'Training Type', accessor: 'trainingType' },
  { Header: 'Notes', accessor: 'notes' },
];
