import { useState, useRef, useEffect } from "react";
import CountUp from "react-countup";

export const Counter = ({ filterParticipants, participantsAwards }) => {
  const [counter, setCounter] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setCounter(true);
        } else {
          setCounter(false);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const data = [
    { no: filterParticipants(participantsAwards, "Completed", "bronze").length, name: "Bronze" },
    { no: filterParticipants(participantsAwards, "Completed", "silver").length, name: "Silver" },
    { no: filterParticipants(participantsAwards, "Completed", "gold").length, name: "Gold" }
  ];

  return (
    <section className="w-full" ref={sectionRef}>
      <div className="flex flex-col items-center w-full ">
        {data.map((i, k) => (
          <div key={k} className="w-full flex justify-between">
            <span className="text-[rgba(192, 192, 192, 0.2)] font-[500] font-Satoshi text-[16px] md:text-[20px] lg:text-[20px]">
              {i.name}
            </span>
            <span className="font-[500] font-Satoshi ml-auto">
              {counter && <CountUp end={i.no} />}
            </span>
            <hr />
          </div>
        ))}
      </div>
    </section>
  );
};
