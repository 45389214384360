import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import HelpersForm from "../../components/forms/helperF";
import HelpersTable from "../../components/tables/helperT";
import {
  addHelper as addHelperService,
  getHelperById,
  updateHelper,
  deleteHelper,
  getHelper,
} from "../../services/helperS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddHelper = () => {
  const [Helpers, setHelpers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newHelper, setNewHelper] = useState({
    helperID: "",
    helperName: "",
    institutionName: "",
    gender: "",
    idNo: "",
    phoneNo: "",
    email: "",
    subCounty: "",
    county: "",
    helperType: "",
    coordinator: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredHelpers, setFilteredHelpers] = useState(Helpers);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedHelperId, setSelectedHelperId] = useState(null);

  useEffect(() => {
    const fetchHelpers = async () => {
      try {
        const fetchedHelpers = await getHelper();
        setHelpers(fetchedHelpers);
        setFilteredHelpers(fetchedHelpers);
      } catch (error) {
        console.error("Error fetching Helpers:", error.response.data);
      }
    };

    fetchHelpers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewHelper((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewHelper((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewHelper = async () => {
    try {
      const helperPayload = { ...newHelper };

      console.log("New Helper Payload:", helperPayload);
      const addedHelper = await addHelperService(helperPayload);
      setHelpers((prev) => [...prev, addedHelper]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding helper:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add helper: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddHelperModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewHelper({
      helperID: "",
      helperName: "",
      institutionName: "",
      gender: "",
      idNo: "",
      phoneNo: "",
      email: "",
      subCounty: "",
      county: "",
      helperType: "",
      coordinator: "",
    });
  };

  const openEditHelperModal = async (helper) => {
    try {
      console.log("Fetching helper with ID:", helper.helperID);
      const fetchedHelper = await getHelperById(helper.helperID);
      //console.log("Fetched Helper:", fetchedHelper);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedHelperId(helper.helperID);
      setNewHelper({
        ...fetchedHelper,
        licenseStartDate: fetchedHelper.licenseStartDate,
        licenseEndDate: fetchedHelper.licenseEndDate,
      });
    } catch (error) {
      console.error(
        `Error fetching helper with ID ${helper.helperID}:`,
        error.response.data
      );
    }
  };

  const updateExistingHelper = async () => {
    try {
      const helperPayload = { ...newHelper };

      //console.log("Updated Helper Payload:", helperPayload);

      const updatedHelper = await updateHelper(selectedHelperId, helperPayload);
      setHelpers((prev) =>
        prev.map((inst) =>
          inst.helperID === selectedHelperId ? updatedHelper : inst
        )
      );
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating helper with ID ${selectedHelperId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update helper: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingHelper = async (helperID) => {
    try {
      await deleteHelper(helperID);
      setHelpers((prev) => prev.filter((inst) => inst.helperID !== helperID));
    } catch (error) {
      console.error(
        `Error deleting helper with ID ${helperID}:`,
        error.response.data
      );
      alert(`Failed to delete helper: ${error.response.data.title}`);
    }
  };

  const closeAddHelperModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteHelperHandler = (helperID) => {
    if (
      window.confirm(
        `Are you sure you want to delete helper with ID ${helperID}?`
      )
    ) {
      deleteExistingHelper(helperID);
    }
  };

   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterHelpers(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterHelpers(searchTerm, value);
  };

  const filterHelpers = (searchTerm, column) => {
    const filtered = Helpers.filter((helper) =>
      helper[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredHelpers(filtered);
  };
  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Helpers</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddHelperModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            <span>Helper</span>
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {Helpers.length > 0 ? (
                  Object.keys(Helpers[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <HelpersTable
          Helpers={Helpers}
          openEditModal={openEditHelperModal}
          deleteHelper={deleteHelperHandler}
        />
      </div>
  
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddHelperModal}
        contentLabel={editMode ? "Edit Helper" : "Add Helper"}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Helper" : "Add Helper"}
        </h2>
        <HelpersForm
          formValues={newHelper}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingHelper : addNewHelper}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddHelperModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddHelper;
