// src/pages/reports/AjsR.js

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import { formatPDFReport } from "../../utils/pdfFormatting";
import { formatExcelReport } from "../../utils/excelFormatting";
import {
  fetchActivityApprovalData,
  activityApprovalHeaders,
} from "../../services/reportsServices/reportAjApprovalS";
import {
  fetchParticipantActivityData,
  participantActivityHeaders,
} from "../../services/reportsServices/reportAjS";
import {
  Printer,
  FileSpreadsheet,
  FileText,
  RefreshCw,
} from "lucide-react";
import apiClient from "../../services/apiClient"; // Ensure the correct path

const AjsR = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState({});
  const [filters, setFilters] = useState({
    // Activity Approval filters
    awardCentre: "",
    region: "",
    awardLeader: "",
    // Participant Activity filters
    adminNumber: "",
    studentName: "",
    awardCenter: "",
    awardLevel: "",
    // Common filters
    dateRange: ["", ""],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedReport) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let data;
      if (selectedReport === "ActivityApprovals") {
        data = await fetchActivityApprovalData();
      } else if (selectedReport === "ParticipantActivities") {
        data = await fetchParticipantActivityData();
      }
      setReportData(data);
      setFilteredData(data);
      extractUniqueOptions(data);
    } catch (error) {
      setError("Error fetching data. Please try again.");
      console.error(`Error fetching ${selectedReport} data:`, error);
    }
    setIsLoading(false);
  };

  const extractUniqueOptions = (data) => {
    const options = {};
    let filterFields = [];

    switch (selectedReport) {
      case "ActivityApprovals":
        filterFields = ["awardCentre", "region", "awardLeader"];
        break;
      case "ParticipantActivities":
        filterFields = ["adminNumber", "studentName", "awardCenter", "awardLevel"];
        break;
      default:
        break;
    }

    filterFields.forEach((field) => {
      options[field] = [...new Set(data.map((item) => item[field]))].filter(
        (option) => option !== undefined && option !== null
      );
    });
    setUniqueOptions(options);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, reportData]);

  const applyFilters = () => {
    let filtered = reportData;

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (key === "dateRange") {
          const [startDate, endDate] = value;
          if (startDate && endDate) {
            filtered = filtered.filter((item) => {
              let itemDate;
              if (selectedReport === "ActivityApprovals") {
                itemDate = new Date(item.activityDate);
              } else if (selectedReport === "ParticipantActivities") {
                itemDate = new Date(item.activityDate); // Adjust based on actual date field
              }
              return (
                itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
              );
            });
          }
        } else {
          filtered = filtered.filter((item) => {
            // Handle case-insensitive filtering for string fields
            if (typeof item[key] === "string") {
              return item[key].toLowerCase() === value.toLowerCase();
            }
            return item[key] === value;
          });
        }
      }
    });

    setFilteredData(filtered);
  };

  const handleExport = async (format) => {
    if (filteredData.length === 0) {
      alert("No data available for export.");
      return;
    }

    let headers;
    if (selectedReport === "ActivityApprovals") {
      headers = activityApprovalHeaders;
    } else if (selectedReport === "ParticipantActivities") {
      headers = participantActivityHeaders;
    }

    if (format === "PDF") {
      const pdfBlob = await formatPDFReport(selectedReport, filteredData, headers);
      // Open PDF in a new tab
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } else if (format === "Excel") {
      formatExcelReport(selectedReport, filteredData, headers);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      dateRange:
        name === "startDate"
          ? [value, prev.dateRange[1]]
          : [prev.dateRange[0], value],
    }));
  };

  const clearFilters = () => {
    setFilters({
      awardCentre: "",
      region: "",
      awardLeader: "",
      adminNumber: "",
      studentName: "",
      awardCenter: "",
      awardLevel: "",
      dateRange: ["", ""],
    });
  };

  const renderFilters = () => {
    switch (selectedReport) {
      case "ActivityApprovals":
        return (
          <div className="grid grid-cols-4 gap-4 mb-4">
            {["awardCentre", "region", "awardLeader"].map((field) => (
              <div key={field}>
                <label
                  className="block text-gray-700 text-sm font-bold mb-1"
                  htmlFor={field}
                >
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </label>
                <select
                  name={field}
                  id={field}
                  value={filters[field]}
                  onChange={handleFilterChange}
                  className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                >
                  <option value="">All</option>
                  {uniqueOptions[field]?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-1"
                htmlFor="startDate"
              >
                Activity Date (From)
              </label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                value={filters.dateRange[0]}
                onChange={handleDateRangeChange}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-1"
                htmlFor="endDate"
              >
                Activity Date (To)
              </label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                value={filters.dateRange[1]}
                onChange={handleDateRangeChange}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
              />
            </div>
          </div>
        );
      case "ParticipantActivities":
        return (
          <div className="grid grid-cols-4 gap-4 mb-4">
            {["adminNumber", "studentName", "awardCenter", "awardLevel"].map(
              (field) => (
                <div key={field}>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor={field}
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <select
                    name={field}
                    id={field}
                    value={filters[field]}
                    onChange={handleFilterChange}
                    className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                  >
                    <option value="">All</option>
                    {uniqueOptions[field]?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )
            )}
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-1"
                htmlFor="startDate"
              >
                Activity Date (From)
              </label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                value={filters.dateRange[0]}
                onChange={handleDateRangeChange}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
              />
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-1"
                htmlFor="endDate"
              >
                Activity Date (To)
              </label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                value={filters.dateRange[1]}
                onChange={handleDateRangeChange}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Function to toggle approval status for ActivityApprovals report
  const toggleApproval = async (id, newStatus) => {
    try {
      await apiClient.patch(`/api/ActivityApproval/${id}`, {
        approval: newStatus,
      });
      // Update the local state to reflect the change
      setReportData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, approval: newStatus } : item
        )
      );
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, approval: newStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating approval status:", error);
      alert("Failed to update approval status. Please try again.");
    }
  };

  const handlePrint = async () => {
    if (filteredData.length === 0) {
      alert("No data available to print.");
      return;
    }

    try {
      let headers;
      if (selectedReport === "ActivityApprovals") {
        headers = activityApprovalHeaders;
      } else if (selectedReport === "ParticipantActivities") {
        headers = participantActivityHeaders;
      }

      const pdfBlob = await formatPDFReport(selectedReport, filteredData, headers);

      // Create a URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open PDF in a new tab for preview
      window.open(pdfUrl, "_blank");

      // Create a hidden iframe for printing
      const printFrame = document.createElement("iframe");
      printFrame.style.display = "none";
      document.body.appendChild(printFrame);

      printFrame.src = pdfUrl;

      printFrame.onload = () => {
        try {
          printFrame.contentWindow.print();
        } catch (error) {
          console.error("Printing failed:", error);
          alert(
            "Printing failed. Please try again or use the browser print function from the preview tab."
          );
        }

        // Clean up
        setTimeout(() => {
          document.body.removeChild(printFrame);
          URL.revokeObjectURL(pdfUrl);
        }, 1000);
      };
    } catch (error) {
      console.error("Error during print:", error);
      alert("An error occurred while trying to print. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="reports-page p-4">
        <h1 className="text-3xl font-bold mb-6">Reports</h1>

        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-bold mb-4 border-b-2 border-blue-300 pb-2">
            Available Reports
          </h2>
          <ul className="space-y-2">
            {["ActivityApprovals", "ParticipantActivities"].map((report) => (
              <li key={report}>
                <button
                  onClick={() => setSelectedReport(report)}
                  className={`w-full text-left p-2 flex items-center ${
                    selectedReport === report ? "bg-gray-200" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/checked.png`}
                    alt="Checkmark"
                    className={`w-6 h-6 ${
                      selectedReport === report ? "block" : "hidden"
                    } mr-3`}
                  />
                  <span
                    className={`font-semibold ${
                      selectedReport === report
                        ? "text-blue-600"
                        : "text-gray-800"
                    }`}
                  >
                    {report}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        {selectedReport && (
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <h2 className="text-xl font-bold mb-4">{selectedReport} Report</h2>
            {isLoading ? (
              <p>Loading data...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold mb-2 sm:mb-0">
                    Filters
                  </h3>
                  <div className="flex flex-wrap justify-center sm:justify-end gap-2">
                    <button
                      onClick={clearFilters}
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 flex items-center"
                    >
                      <RefreshCw className="w-4 h-4 mr-2" /> Clear Filters
                    </button>
                    <button
                      onClick={() => handleExport("PDF")}
                      className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 flex items-center"
                    >
                      <FileText className="w-4 h-4 mr-2" /> Export to PDF
                    </button>
                    <button
                      onClick={() => handleExport("Excel")}
                      className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center"
                    >
                      <FileSpreadsheet className="w-4 h-4 mr-2" /> Export to
                      Excel
                    </button>
                    <button
                      onClick={handlePrint}
                      className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                    >
                      <Printer className="w-4 h-4 mr-2" /> Print
                    </button>
                  </div>
                </div>
                {renderFilters()}
                <div className="overflow-x-auto">
                  <h3 className="text-lg font-bold mb-2">Filtered Results</h3>
                  <p className="text-red-500 font-semibold text-2xl">
                    Total Records: {filteredData.length}
                  </p>
                  <div className="max-h-96 overflow-y-auto">
                    <table className="w-full bg-white border border-gray-300">
                      <thead className="sticky top-0 bg-white">
                        <tr>
                          {(selectedReport === "ActivityApprovals"
                            ? activityApprovalHeaders
                            : participantActivityHeaders
                          ).map((header, index) => (
                            <th key={index} className="py-2 px-4 border-b">
                              {header.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item, index) => (
                          <tr key={index}>
                            {(selectedReport === "ActivityApprovals"
                              ? activityApprovalHeaders
                              : participantActivityHeaders
                            ).map((header, idx) => {
                              // Handle custom cell rendering for ActivityApproval's Approval checkbox
                              if (
                                selectedReport === "ActivityApprovals" &&
                                header.accessor === "approval"
                              ) {
                                return (
                                  <td
                                    key={idx}
                                    className="py-2 px-4 border-b text-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.approval}
                                      onChange={(e) =>
                                        toggleApproval(
                                          item.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </td>
                                );
                              }
                              return (
                                <td
                                  key={idx}
                                  className="py-2 px-4 border-b whitespace-nowrap"
                                >
                                  {item[header.accessor]}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AjsR;
