import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import AwardsForm from "../../components/forms/participantAwardsF";
import AwardsTable from "../../components/tables/participantsAwardT";
import { addawardsService, getAwardsById, updateAwards, deleteAwards, getAwards } from "../../services/participantAwardS";
import { customStylesModal } from "../../styles/customStylesModal";

Modal.setAppElement("#root");

const ParticipantDetailsAndAwardsModal = ({ isOpen, onRequestClose, participantDetails, forceAwardAddition }) => {
  const [showAwardsForm, setShowAwardsForm] = useState(false);
  const [awards, setAwards] = useState([]);
  const [filteredAwards, setFilteredAwards] = useState([]);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedAwardID, setSelectedAwardID] = useState(null);
  const [isAwardsModalOpen, setIsAwardsModalOpen] = useState(false);
  const [hasAward, setHasAward] = useState(false);
  const [newAwards, setNewAwards] = useState({
    awardID: "",
    adminNumber: participantDetails?.adminNumber || "",
    studentName: participantDetails?.name || "",
    institutionName: participantDetails?.institutionName || "",
    levelName: "",
    startDate: "",
    expectedEndDate: "",
    actualEndDate: "",
    status: "",
    certNo: "",
    notes: "",
  });

  useEffect(() => {
    if (isAwardsModalOpen) {
      fetchAwards();
    }
  }, [isAwardsModalOpen, participantDetails]);

  const fetchAwards = async () => {
    try {
      const fetchedAwards = await getAwards();
      setAwards(fetchedAwards);
      const participantAwards = fetchedAwards.filter(
        (award) => award.adminNumber === participantDetails?.adminNumber
      );
      setFilteredAwards(participantAwards);
    } catch (error) {
      console.error("Error fetching awards:", error.response?.data);
    }
  };

  const generateAwardID = (levelName, currentId) => {
    const prefix = { Bronze: 'B', Silver: 'S', Gold: 'G' }[levelName] || '';
    return `${prefix}${String(currentId).padStart(3, '0')}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAwards((prev) => ({ ...prev, [name]: value }));

    if (name === 'levelName') {
      const newId = awards.filter(award => award.levelName === value).length + 1;
      const newAwardID = generateAwardID(value, newId);
      setNewAwards(prev => ({ ...prev, awardID: newAwardID }));
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewAwards((prev) => ({ ...prev, [name]: value }));
  };

  const addNewAward = async () => {
    try {
      if (awards.some(award => award.adminNumber === newAwards.adminNumber && award.levelName === newAwards.levelName)) {
        alert('This admission number already has an award for the same level.');
        return;
      }

      if (awards.some(award => award.adminNumber === newAwards.adminNumber && award.levelName !== newAwards.levelName && award.status !== 'Completed')) {
        alert('You cannot enroll in a new award level until all current awards are completed.');
        return;
      }

      if (newAwards.status === 'Completed' && !newAwards.certNo) {
        alert('Certificate number is required when status is set to Completed.');
        setErrors((prevErrors) => ({
          ...prevErrors,
          certNo: 'Certificate number is required for completed awards.'
        }));
        return;
      }

      const addedAward = await addawardsService({ ...newAwards, awardID: String(newAwards.awardID) });
      setAwards((prev) => [...prev, addedAward]);
      closeAwardsModal();
    } catch (error) {
      console.error("Error adding awards:", error.response?.data);
      setErrors(error.response?.data?.errors || {});
      alert(`Failed to add awards: ${error.response?.data?.title || 'Unknown error'}\nDetails: ${JSON.stringify(error.response?.data?.errors, null, 2)}`);
    }
  };

  const openAddAwardsModal = () => {
    setEditMode(false);
    setShowAwardsForm(true);
    setIsAwardsModalOpen(true);
    setNewAwards({
      awardID: "",
      adminNumber: participantDetails?.adminNumber || "",
      studentName: participantDetails?.name || "",
      institutionName: participantDetails?.institutionName || "",
      levelName: "",
      startDate: "",
      expectedEndDate: "",
      actualEndDate: "",
      status: "",
      certNo: "",
      notes: "",
    });
    setErrors({});
  };

  const openEditAwardsModal = async (award) => {
    try {
      const fetchedAward = await getAwardsById(String(award.awardID));
      setEditMode(true);
      setShowAwardsForm(true);
      setIsAwardsModalOpen(true);
      setSelectedAwardID(award.awardID);
      setNewAwards(fetchedAward);
      setErrors({});
    } catch (error) {
      console.error(`Error fetching awards with ID ${award.awardID}:`, error.response?.data);
    }
  };

  const updateExistingAward = async () => {
    try {
      if (newAwards.status === 'Completed' && !newAwards.certNo) {
        alert('Certificate number is required when status is set to Completed.');
        setErrors((prevErrors) => ({
          ...prevErrors,
          certNo: 'Certificate number is required for completed awards.'
        }));
        return;
      }

      const updatedAward = await updateAwards(selectedAwardID, newAwards);
      setAwards((prev) => prev.map((award) => award.awardID === selectedAwardID ? updatedAward : award));
      closeAwardsModal();
    } catch (error) {
      console.error(`Error updating awards with ID ${selectedAwardID}:`, error.response?.data);
      setErrors(error.response?.data?.errors || {});
      alert(`Failed to update awards: ${error.response?.data?.title || 'Unknown error'}\nDetails: ${JSON.stringify(error.response?.data?.errors, null, 2)}`);
    }
  };

  const deleteExistingAward = async (awardID) => {
    try {
      await deleteAwards(awardID);
      setAwards((prev) => prev.filter((award) => award.awardID !== awardID));
    } catch (error) {
      console.error(`Error deleting awards with ID ${awardID}:`, error.response?.data);
      alert(`Failed to delete awards: ${error.response?.data?.title || 'Unknown error'}`);
    }
  };

  const closeAwardsModal = () => {
    if (!forceAwardAddition || hasAward) {
      setIsAwardsModalOpen(false);
      setEditMode(false);
      setShowAwardsForm(false);
      setErrors({});
    } else {
      alert("Please add at least one award before closing.");
    }
  };

  const handleRequestClose = () => {
    if (!forceAwardAddition || hasAward) {
      onRequestClose();
    } else {
      alert("Please add at least one award before closing.");
    }
  };

  const deleteAwardsHandler = (awardID) => {
    if (window.confirm(`Are you sure you want to delete award with ID ${awardID}?`)) {
      deleteExistingAward(awardID);
    }
  };
  return (
    <>
      <Modal
        style={{
          ...customStylesModal,
          content: {
            ...customStylesModal.content,
            maxWidth: '800px',
            width: '90%',
            padding: '2rem',
            backgroundColor: '#f0f4f8',
          }
        }}
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        contentLabel="Participant Details"
      >
        {participantDetails ? (
          <div className="bg-white rounded-xl p-8 space-y-6">
            <h1 className="text-3xl font-bold text-indigo-700 border-b-2 border-indigo-200 pb-2">Participant Details</h1>
            <div className="grid grid-cols-2 gap-6">
              {participantDetails.passportPhoto && (
                <div className="col-span-2 flex justify-center">
                  <img src={process.env.REACT_APP_API_BASE_URL + participantDetails.passportPhoto} alt="Passport" className="w-40 h-40 object-cover rounded-full border-4 border-indigo-200" />
                </div>
              )}
              <InfoItem label="Admin Number" value={participantDetails.adminNumber} />
              <InfoItem label="Name" value={participantDetails.name} />
              <InfoItem label="Award Center" value={participantDetails.institutionName} />
              <InfoItem label="Date of Birth" value={participantDetails.dob} />
              <InfoItem label="Gender" value={participantDetails.gender} />
              <InfoItem label="Age" value={participantDetails.age} />
              <InfoItem label="Phone Number" value={participantDetails.phoneNumber} />
              <InfoItem label="Email" value={participantDetails.email} />
              <InfoItem label="At Risk" value={participantDetails.atRisk} />
              <InfoItem label="Awards" value={participantDetails.awards || "No awards yet"} />
            </div>
            <div className="flex justify-end space-x-4 mt-8">
              <Button onClick={openAddAwardsModal}>Add Awards</Button>
              <Button onClick={() => setIsAwardsModalOpen(true)}>View Awards</Button>
              <Button onClick={handleRequestClose} disabled={forceAwardAddition && !hasAward}>
            Close
          </Button>
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">No participant details available</p>
        )}
      </Modal>

      <Modal
        style={{
          ...customStylesModal,
          content: {
            ...customStylesModal.content,
            maxWidth: '1200px',
            width: '95%',
            height: '90%',
            padding: '2rem',
            backgroundColor: '#f0f4f8',
          }
        }}
        isOpen={isAwardsModalOpen}
        onRequestClose={closeAwardsModal}
        contentLabel="Participant Awards"
      >
        <div className="bg-white rounded-xl p-8 space-y-6 h-full flex flex-col">
          <h2 className="text-3xl font-bold ">Participant Awards</h2>
          <div className="flex-grow overflow-auto">
            {showAwardsForm ? (
              <AwardsForm
                formValues={newAwards}
                handleInputChange={handleInputChange}
                handleDateChange={handleDateChange}
                errors={errors}
              />
            ) : (
              <div className="h-full">
                <AwardsTable
                  awards={filteredAwards}
                  openEditModal={openEditAwardsModal}
                  deleteAwardsHandler={deleteAwardsHandler}
                />
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <Button onClick={editMode ? updateExistingAward : addNewAward} primary>
              {editMode ? "Update" : "Save"}
            </Button>
            <Button onClick={closeAwardsModal}>Close</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="bg-indigo-50 p-3 rounded-lg">
    <span className="font-medium text-indigo-700">{label}:</span>{' '}
    <span className="text-gray-800">{value}</span>
  </div>
);

const Button = ({ children, onClick, primary }) => (
  <button
    onClick={onClick}
    className={`px-6 py-2 rounded-lg font-medium transition duration-200 ${
      primary
        ? "bg-indigo-600 text-white hover:bg-indigo-700"
        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
    }`}
  >
    {children}
  </button>
);

export default ParticipantDetailsAndAwardsModal;