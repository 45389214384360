import React,{memo} from 'react';
import { Pie, Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, Filler, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';

// Register components to ChartJS
ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, Filler);

const ChartComponent = memo(({ participants, loading }) => {
  const bronzeParticipants = participants?.filter(participant => participant.levelName === "Bronze") || [];
  const silverParticipants = participants?.filter(participant => participant.levelName === "Silver") || [];
  const goldParticipants = participants?.filter(participant => participant.levelName === "Gold") || [];

  const barData = {
    labels: ["Bronze", "Silver", "Gold"],
    datasets: [
      {
        label: 'Highest Participants per Award Level',
        data: [bronzeParticipants.length, silverParticipants.length, goldParticipants.length],
        backgroundColor: ['rgba(205, 127, 50, 0.2)', 'rgba(192, 192, 192, 0.2)', 'rgba(255, 215, 0, 0.2)'],
        borderColor: ['rgba(205, 127, 50, 1)', 'rgba(192, 192, 192, 1)', 'rgba(255, 215, 0, 1)'],
        borderWidth: 1
      }
    ]
  };

  const monthlyParticipation = Array(12).fill(0);
  participants?.forEach(participant => {
    if (participant?.startDate) {
      const startMonth = new Date(participant.startDate).getMonth();
      monthlyParticipation[startMonth] += 1;
    }
  });

  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Participants',
        data: monthlyParticipation,
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        tension: 0.4,
      }
    ]
  };

  const skeletonLoader = (
    <div className="animate-pulse">
      <div className="h-6 bg-gray-300 rounded w-1/4 mb-4"></div>
      <div className="h-32 bg-gray-300 rounded"></div>
    </div>
  );

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-1 items-center gap-2 mt-10 w-full mx-auto">
      <div className="bg-white p-3 rounded-lg w-auto h-full" style={{ boxShadow: "0 4px 6px -1px rgba(59, 130, 246, 0.1), 0 2px 4px -1px rgba(59, 130, 246, 0.06)" }}>
        <h2 className="text-2xl font-semibold text-main mb-4">Award Levels Summary</h2>
        <div className="min-h-[100px]">
          {loading ? skeletonLoader : (
            <Bar
              data={barData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: { color: "#4A5568" },
                    grid: { color: "#EDF2F7" },
                  },
                  x: {
                    ticks: { color: "#4A5568" },
                    grid: { color: "#EDF2F7" },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                    labels: { color: "#4A5568" },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
      <div className="bg-white p-3 rounded-lg w-auto h-full" style={{ boxShadow: "0 4px 6px -1px rgba(59, 130, 246, 0.1), 0 2px 4px -1px rgba(59, 130, 246, 0.06)" }}>
        <h2 className="text-2xl font-semibold text-main mb-4">Participation Summary</h2>
        <div className="h-auto">
          {loading ? skeletonLoader : (
            <Line
              data={lineData}
              options={{
                scales: {
                  y: { beginAtZero: true, ticks: { color: "#4A5568" }, grid: { color: "#EDF2F7" } },
                  x: { ticks: { color: "#4A5568" }, grid: { color: "#EDF2F7" } },
                },
                plugins: {
                  legend: { display: true, position: "top", labels: { color: "#4A5568" } },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const participation = context.raw;
                        return participation > 50 ? `Participants: ${participation} (High)` : `Participants: ${participation}`;
                      },
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ChartComponent;
