import React, { useState, useEffect } from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';
import { getInstitutions } from '../../services/awardCenterS'
import { getParticipantActivity} from '../../services/ajS'
import { getUsersByUsername } from '../../services/usersS';

const AjReportingForm = ({ formValues, setFormValues, handleInputChange, errors,handleFileChange }) => {
  const [institutions, setInstitutions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
      
      try {
        if (loggedInUser?.role.name === "Award Leader") {
          // Fetch only the institution of the logged-in Award Leader
          const userData = await getUsersByUsername(loggedInUser.username);
          
          if (userData?.awardCenter?.institutionName) {
            // Set only the Award Leader's institution
            setInstitutions([userData.awardCenter]); // Wrap in an array for consistency
          } else {
            console.warn("No institution found for the logged-in user");
          }
        } else {
          // Fetch all institutions for other roles
          const fetchedInstitutions = await getInstitutions();
          const sortedInstitutions = fetchedInstitutions.sort((a, b) =>
            a.institutionName.localeCompare(b.institutionName)
          );
          setInstitutions(sortedInstitutions);
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  //Fetch activites undergone
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedActivities = await getParticipantActivity();
        setActivities(fetchedActivities);
      } catch (error) {
        console.error('Error fetching Activities:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <form className={styles.form}>
      <div className="space-y-4">
        <div>
          <label htmlFor="awardCentre">Award Center:</label>
          {loading ? (
            <p>Loading award centers...</p>
          ) : (
            <select
              name="awardCentre"
              value={formValues.awardCentre}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Center</option>
              {institutions.map((institution) => (
                <option key={institution.institutionID} value={institution.awardCentre}>
                  {institution.institutionName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="awardLeader">Award Leader:</label>
          <Input
            name="awardLeader"
            placeholder="Award Leader"
            value={formValues.awardLeader}
            onChange={handleInputChange}
          />
          {errors.awardLeader && <p className="text-red-500">{errors.awardLeader[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityName">Activity Name:</label>
          {loading ? (
            <p>Loading activities...</p>
          ) : (
            <select
              name="activityName"
              value={formValues.activityName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Activity</option>
              {activities.map((activities) => (
                <option key={activities.id} value={activities.activityName}>
                  {activities.activityName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="participantsNo">No. of Participants:</label>
          <Input
            type="number"
            name="participantsNo"
            placeholder="Participants No"
            value={formValues.participantsNo}
            onChange={handleInputChange}
          />
          {errors.participantsNo && <p className="text-red-500">{errors.participantsNo[0]}</p>}
        </div>

        <div>
          <label htmlFor="region">Region:</label>
          <select className="w-full p-2 border border-gray-300 rounded"
            name="region"
            value={formValues.region}
            onChange={handleInputChange}
          >
            <option value="" disabled>Select a region</option>
            <option value="Central">Central</option>
            <option value="Coast">Coast</option>
            <option value="Lower Eastern">Lower Eastern</option>
            <option value="Nairobi">Nairobi</option>
            <option value="North Eastern">North Eastern</option>
            <option value="North Rift">North Rift</option>
            <option value="Nyanza">Nyanza</option>
            <option value="South Rift">South Rift</option>
            <option value="Upper Eastern">Upper Eastern</option>
            <option value="Western">Western</option>
          </select>
          {errors.region && <p className="text-red-500">{errors.region[0]}</p>}
        </div>

        <div>
          <label htmlFor="uploadReport">Upload Form:</label>
          <Input
            type="file"
            name="uploadReport"
            onChange={handleFileChange}
          />
          {errors.uploadReport && <p className="text-red-500">{errors.uploadReport[0]}</p>}
        </div>

        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default AjReportingForm;
