import React, { useState, useEffect, useMemo } from 'react';
import Table from '../common/Table';
import Dropdown from '../Dropdown/dropDown';
import { getUsersByUsername } from '../../services/usersS';

const AjApprovalTable = ({ AjApproval, openEditModal, deleteAjApproval, openApprovalModal }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
  let user = JSON.parse(localStorage.getItem("user"))
  let role =  user.user.role.name == "Admin" || user.user.role.name == "Staff"
  const columns = [
    { 
      Header: 'Award Center', 
      accessor: 'awardCenter',
      Cell: ({ row }) => (
        <div className="flex items-center">
          <span className="mr-2">{row.original.awardCentre}</span>
          { role &&(<button
            onClick={() => openApprovalModal(row.original)}
            className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
          >
            Review
          </button>)}
        </div>
      )
    },      
    { Header: 'Award Leader', accessor: 'awardLeader' },
    { Header: 'Region', accessor: 'region' },    
    { Header: 'Activity Name', accessor: 'activityName' },
    { Header: 'Activity Date', accessor: 'activityDate' },  
    { Header: 'Apply Date', accessor: 'applyDate' },        
    { Header: 'Assessors', accessor: 'assessors' },        
    { Header: 'No. of Participants', accessor: 'participantsNo' },
    { Header: 'Upload Form', accessor: 'uploadForm' },      
    { Header: 'Notes', accessor: 'notes' },
  ];

  const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName);
        } else {
          console.warn("No institution name found in user data");
        }

      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

  // Fetch user's institution when component mounts
  useEffect(() => {
    fetchUserInstitution();
    
  }, []);

    // Filter AjApproval based on user role and award center
    const filteredAjApproval = useMemo(() => {
      if (!loggedInUser || !AjApproval) return [];
      if (loggedInUser.role.name === "Award Leader" && userInstitution) {
        return AjApproval.filter(approval => approval.awardCenter === userInstitution);
      }
  
      // For other roles, show all approvals
      return AjApproval;
    }, [loggedInUser, AjApproval, userInstitution]);


  const renderRowActions = (activity) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>openEditModal(activity)}
      onDelete={()=>deleteAjApproval(activity.id)}
    />
  </div> 
  );



  return (
    <Table
      columns={columns}
      data={filteredAjApproval}
      renderRowActions={renderRowActions}
    />
  );
};

export default AjApprovalTable;