import apiClient from './apiClient';
import { logAction } from '../utils/logger';
const addAjApproval = async (AjApprovalData) => {
  try {
    const response = await apiClient.post('/api/ActivityApproval', AjApprovalData);
    if(response){
      logAction("Created Aj Approal","AJ Approal",`Created the following Aj Approval ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in addAjApproval:', error.response.data);
    throw error;
  }
};

const getAjApprovalById = async (ID) => {
  try {
    const response = await apiClient.get(`/api/ActivityApproval/${ID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const updateAjApproval = async (ID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ActivityApproval/${ID}`, updatedData);
    if(response){
      logAction("Updated AJ Approval","AJ Approval",`Updated the following Aj Approval ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const deleteAjApproval = async (ID) => {
  try {
   const response= await apiClient.delete(`/api/ActivityApproval/${ID}`); 
   if(response){
      logAction("Deleted AJ","AJ",`Deleted the following AjApproval ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    console.error(`Error deleting AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const getAjApproval = async () => {
  try {
    const response = await apiClient.get('/api/ActivityApproval');
    return response.data;
  } catch (error) {
    console.error('Error fetching AjApproval:', error.response?.data || error.message);
    throw error;
  }
};

export { 
  addAjApproval, 
  getAjApprovalById, 
  updateAjApproval, 
  deleteAjApproval, 
  getAjApproval 
};
