import apiClient from './apiClient';
import { logAction } from '../utils/logger';
const addParticipantActivity = async (ParticipantActivityData) => {
  try {
    const response = await apiClient.post('/api/ParticipantActivity', ParticipantActivityData);
    if(response){
      logAction("Created AJ","Ajs",`Created the following Aj ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in addParticipantActivity:', error.response.data);
    throw error;
  }
};

const getParticipantActivityById = async (ID) => {
  try {
    const response = await apiClient.get(`/api/ParticipantActivity/${ID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ParticipantActivity with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const updateParticipantActivity = async (ID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ParticipantActivity/${ID}`, updatedData);
    if(response){
      logAction("Updated AJ","AJ",`Updated the following aj ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating ParticipantActivity with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const deleteParticipantActivity = async (ID) => {
  try {
   const response = await apiClient.delete(`/api/ParticipantActivity/${ID}`);
   if(response){
    logAction("Deleted AJ","AJ",`Deleted the following aj ${JSON.stringify(response.data)}`)
  }
  } catch (error) {
    console.error(`Error deleting ParticipantActivity with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const getParticipantActivity = async () => {
  try {
    const response = await apiClient.get('/api/ParticipantActivity');
    return response.data;
  } catch (error) {
    console.error('Error fetching ParticipantActivity:', error.response?.data || error.message);
    throw error;
  }
};

export { 
  addParticipantActivity, 
  getParticipantActivityById, 
  updateParticipantActivity, 
  deleteParticipantActivity, 
  getParticipantActivity 
};
