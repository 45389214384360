import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import  {customStyles} from "../../styles/customStyles"
import { FaFilter } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingTypeForm from '../../components/forms/trainingTypeF';
import TrainingTypeTable from '../../components/tables/trainingTypeT';
import { addTrainingType as addTrainingTypeService, getTrainingTypeById, updateTrainingType, deleteTrainingType, getTrainingType } from '../../services/trainingTypeS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddTrainingType = () => {
  const [trainingtypes, setTrainingTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTrainingTypes, setNewTrainingTypes] = useState({
    typeID: '',
    typeName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredTrainingTypes, setFilteredTrainingTypes] = useState(trainingtypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingTypesID, setSelectedTrainingTypesID] = useState(null);

  useEffect(() => {
    const fetchedtrainingtype = async () => {
      try {
        const fetchedTrainingTypes = await getTrainingType();
        setTrainingTypes(fetchedTrainingTypes);
        setFilteredTrainingTypes(fetchedTrainingTypes); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching trainingtype:', error.response.data);
      }
    };

    fetchedtrainingtype();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTrainingTypes = async () => {
    try {
      const trainingtypePayload = {
        typeID: String(newTrainingTypes.typeID), // Ensure TrainingTypesID is a string
        typeName: newTrainingTypes.typeName,
        notes: newTrainingTypes.notes,
      };
  
      await addTrainingTypeService(trainingtypePayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding trainingtype:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add trainingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddTrainingTypesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTrainingTypes({
      typeID: '',
      typeName: '',
      notes: '',
    });
  };

  const openEditTrainingTypesModal = async (typeID) => {
    try {
      const fetchedTrainingTypes = await getTrainingTypeById(String(typeID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingTypesID(typeID);
      setNewTrainingTypes({
        ...fetchedTrainingTypes,
      });
    } catch (error) {
      console.error(`Error fetching trainingtype with ID ${typeID}:`, error.response.data);
    }
  };

  const updateExistingTrainingTypes = async () => {
    try {
      const trainingtypePayload = { ...newTrainingTypes };
      await updateTrainingType(selectedTrainingTypesID, trainingtypePayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating trainingtype with ID ${selectedTrainingTypesID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update trainingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingTrainingTypes = async (typeID) => {
    try {
      await deleteTrainingType(typeID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      console.error(`Error deleting trainingtype with ID ${typeID}:`, error.response.data);
      alert(`Failed to delete trainingtype: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingTypesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingTypeHandler = (typeID) => {
    if (window.confirm(`Are you sure you want to delete trainingtype with ID ${typeID}?`)) {
      deleteExistingTrainingTypes(typeID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterTrainingTypes(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterTrainingTypes(searchTerm, value);
    };
  
    const filterTrainingTypes = (searchTerm, column) => {
      const filtered = trainingtypes.filter((trainingtype) =>
        trainingtype[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredTrainingTypes(filtered);
    };
  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Training Types</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddTrainingTypesModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            <span>Types</span>
          </button>
  
          <form className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {trainingtypes.length > 0 ? (
                  Object.keys(trainingtypes[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </form>
        </div>
  
        <TrainingTypeTable
          trainingtypes={filteredTrainingTypes}
          openEditModal={openEditTrainingTypesModal}
          deleteTrainingType={deleteTrainingTypeHandler}
        />
      </div>
  
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddTrainingTypesModal}
        contentLabel={editMode ? "Edit Training Types" : "Add Training Types"}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Training Types" : "Add Training Types"}
        </h2>
        <TrainingTypeForm
          formValues={newTrainingTypes}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingTrainingTypes : addNewTrainingTypes}
            className="bg-indigo-600 text-white px-5 py-2 rounded-md mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddTrainingTypesModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded-md hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  
  );
};

export default AddTrainingType;
