import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStylesModal } from '../../styles/customStylesModal';

const AjApprovalModal = ({ isOpen, onClose, application, onApprove, onReject }) => {
  const [assessors, setAssessors] = useState('');
  const [notes, setNotes] = useState(application?.notes || '');
  const [error, setError] = useState('');

  if (!application) {
    return null;
  }

  const getApprovalStatus = (application) => {
    if (application.approval === "true") {
      return 'Approved';
    } else if (application.approval === "false") {
      return 'Rejected';
    }
    return 'Pending';
  };

  const approvalStatus = getApprovalStatus(application);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-600';
      case 'Rejected':
        return 'text-red-600';
      case 'Pending':
        return 'text-yellow-600';
      default:
        return 'text-gray-600';
    }
  };

  const handleStatusChange = (newStatus, applicationId) => {
    setError('');

    if (newStatus === 'Rejected' && !notes.trim()) {
      setError('Please provide notes explaining the rejection reason');
      return;
    }

    const payload = { id: applicationId, approval: newStatus === 'Approved' };
    if (newStatus === 'Approved') {
      payload.assessors = assessors;
      onApprove(payload);
    } else if (newStatus === 'Rejected') {
      payload.notes = notes;
      onReject(payload.id);
    }
  };

  const statusColor = getStatusColor(approvalStatus);

  const DataRow = ({ label, value, className = "" }) => (
    <div className={`py-2 ${className}`}>
      <span className="font-semibold text-gray-700">{label}:</span>{' '}
      <span className="text-gray-900">{value}</span>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStylesModal}
      contentLabel="Review Adventurous Journey Application"
    >
      <div className="bg-white rounded-xl p-8 shadow-lg">
        <h2 className="text-3xl font-bold text-indigo-700 border-b-2 border-indigo-200 pb-4 mb-6">
          Review Adventurous Journey Application
        </h2>
        
        <div className="grid grid-cols-2 gap-x-8 mb-6">
          <div className="space-y-1">
            <DataRow label="Award Center" value={application.awardCentre} />
            <DataRow label="Award Leader" value={application.awardLeader} />
            <DataRow label="Region" value={application.region} />
            <DataRow label="Activity Name" value={application.activityName} />
            <DataRow label="Upload Form" value={application.uploadForm ? 'Uploaded' : 'Not Uploaded'} />
            <DataRow label="Notes" value={application.notes} />
          </div>
          <div className="space-y-1">
            <DataRow label="Activity Date" value={application.activityDate} />
            <DataRow label="Apply Date" value={application.applyDate} />
            <DataRow label="Assessors" value={application.assessors} />
            <DataRow label="No. of Participants" value={application.participantsNo} />
            <DataRow 
              label="Approval Status" 
              value={
                <span className={`font-semibold ${statusColor}`}>
                  {approvalStatus}
                </span>
              }
            />
            <DataRow label="Consent" value={application.consent ? 'Yes' : 'No'} />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-8 mb-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="notes" className="block text-gray-700 font-semibold mb-2">
                Notes {approvalStatus === 'Rejected' && <span className="text-red-500">*</span>}
              </label>
              <textarea
                id="notes"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  if (e.target.value.trim()) {
                    setError('');
                  }
                }}
                placeholder={approvalStatus === 'Rejected' ? "Please provide rejection reason" : "Enter notes"}
                className={`border rounded-lg py-2 px-4 w-full h-24 ${
                  error ? 'border-red-500' : 'border-gray-300'
                } focus:ring-2 focus:ring-indigo-200 focus:border-indigo-400 transition-colors`}
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label htmlFor="assessors" className="block text-gray-700 font-semibold mb-2">
                Assign Assessors
              </label>
              <input
                type="text"
                id="assessors"
                value={assessors}
                onChange={(e) => setAssessors(e.target.value)}
                placeholder="Enter assessors"
                className="border rounded-lg py-2 px-4 w-full border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:border-indigo-400 transition-colors"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 pt-4 border-t border-gray-200">
          <button
            onClick={() => handleStatusChange('Approved', application.id)}
            className={`px-4 py-2 rounded-lg font-semibold transition-colors ${
              approvalStatus === 'Approved'
                ? 'bg-green-500 text-white'
                : 'bg-green-100 text-green-700 hover:bg-green-200'
            }`}
          >
            Approve
          </button>
          <button
            onClick={() => handleStatusChange('Rejected', application.id)}
            className={`px-4 py-2 rounded-lg font-semibold transition-colors ${
              approvalStatus === 'Rejected'
                ? 'bg-red-500 text-white'
                : 'bg-red-100 text-red-700 hover:bg-red-200'
            }`}
          >
            Reject
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-lg transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AjApprovalModal;