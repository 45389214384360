import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from "axios"

import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import AjReportingForm from '../../components/forms/ajReportingF';
import AjReportingTable from '../../components/tables/ajReportingT';
import { addAjReporting, getAjReportingById, updateAjReporting, deleteAjReporting, getAjReporting  } from '../../services/ajsReportingS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddAjReporting = () => {
  const [AjReporting, setAjReportings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAjReporting, setNewAjReporting] = useState({
    
    awardCentre: '',
    awardLeader: '',
    activityName: '',
    participantsNo: '',
    region: '',
    uploadReport: null, 
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredAjReportings, setFilteredAjReportings] = useState(AjReporting);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedAjReportingId, setSelectedAjReportingId] = useState(null);

  useEffect(() => {
    const fetchAjReportings = async () => {
      try {
        const fetchedAjReportings = await getAjReporting();
        setAjReportings(fetchedAjReportings);
        setFilteredAjReportings(fetchedAjReportings);
      } catch (error) {
        console.error('Error fetching AjReporting:', error.response.data);
      }
    };

    fetchAjReportings();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAjReporting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewAjReporting((prevData) => ({
      ...prevData,
      [name]: files[0], // assuming you want to handle single file uploads
    }));

    // You can add validation logic here for file type and size if needed
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewAjReporting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewAjReporting = async () => {
    try {
      const formData = new FormData()

      formData.append("ParticipantsNo", newAjReporting.participantsNo);
      formData.append("Region", newAjReporting.region);
      formData.append("AwardCentre", newAjReporting.awardCentre);
      formData.append("ActivityName", newAjReporting.activityName);
      formData.append("Notes", newAjReporting.notes);
      formData.append("AwardLeader", newAjReporting.awardLeader);
      formData.append("UploadReport", newAjReporting.uploadReport);
  
      console.log(newAjReporting)
      const addedParticipantActivityReporting = await axios.post(process.env.REACT_APP_API_BASE_URL + "/api/ActivityReporting", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(addedParticipantActivityReporting)
      
      setIsModalOpen(false);
      setUpdate(prev=>!prev)
      setErrors({});
    } catch (error) {
      console.error('Error adding AjReporting:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add AjReporting: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddAjReportingModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewAjReporting({
        awardCentre: '',
        awardLeader: '',
        activityName: '',
        participantsNo: '',
        region: '',
        uploadReport: null, 
        notes: '',
    });
  };

  const openEditAjReportingModal = async (ID) => {
    try {
      console.log('Fetching AjReporting with ID:', ID);
      const fetchedAjReporting = await getAjReportingById(ID);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedAjReportingId(ID);
      setNewAjReporting({
        ...fetchedAjReporting,
        startDate: fetchedAjReporting.startDate,
        endDate: fetchedAjReporting.endDate,
      });
    } catch (error) {
      console.error(`Error fetching AjReporting with ID ${ID}:`, error.response.data);
    }
  };  
  
  const updateExistingAjReporting = async () => {
    try {
      const AjReportingPayload = { ...newAjReporting };
      await updateAjReporting(selectedAjReportingId, AjReportingPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating AjReporting with ID ${selectedAjReportingId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update AjReporting: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingAjReporting = async (ID) => {
    try {
      await deleteAjReporting(ID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting AjReporting with ID ${ID}:`, error.response.data);
      alert(`Failed to delete AjReporting: ${error.response.data.title}`);
    }
  };

  const closeAddAjReportingModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteAjReportingHandler = (ID) => {
    if (window.confirm(`Are you sure you want to delete AjReporting with ID ${ID}?`)) {
      deleteExistingAjReporting(ID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterAjReportings(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterAjReportings(searchTerm, value);
    };
  
    const filterAjReportings = (searchTerm, column) => {
      const filtered = AjReporting.filter((AjReporting) =>
        AjReporting[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredAjReportings(filtered);
    };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">AjReportings</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddAjReportingModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add AjReporting
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {AjReporting.length > 0 ? (
                  Object.keys(AjReporting[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search AjReporting..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <AjReportingTable
          AjReporting={filteredAjReportings}
          openEditModal={openEditAjReportingModal}
          deleteAjReporting={deleteAjReportingHandler}
        />
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddAjReportingModal}
        contentLabel={editMode ? "Edit AjReporting" : "Add AjReporting"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit AjReporting" : "Add AjReporting"}
        </h2>
        <AjReportingForm
          formValues={newAjReporting}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleFileChange={handleFileChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingAjReporting : addNewAjReporting}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddAjReportingModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddAjReporting;
