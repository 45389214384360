import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { customStyles } from "../../styles/customStyles";
import StatusForm from "../../components/forms/awardCStatusF";
import StatusTable from "../../components/tables/awardCStatusT";
import {
  addStatus as addStatusService,
  getStatusById,
  updateStatus,
  deleteStatus,
  getStatus,
} from "../../services/awardCStatusS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddStatus = () => {
  const [status, setStatus] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState({
    stateID: "",
    statusName: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedColumn, setSelectedColumn] = useState("statusName");
  const [filteredStatus, setFilteredStatus] = useState(status);
  const [searchTerm, setSearchTerm] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedStatusID, setSelectedStatusID] = useState(null);

  useEffect(() => {
    const fetchedstatus = async () => {
      try {
        const fetchedStatus = await getStatus();
        setStatus(fetchedStatus);
        setFilteredStatus(fetchedStatus);
      } catch (error) {
        console.error("Error fetching status:", error.response.data);
      }
    };

    fetchedstatus();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterStatus = (searchTerm, column) => {
    const filtered = status?.filter((status) =>
      status[column].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStatus(filtered);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterStatus(searchTerm, value);
  };

  //handle search change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterStatus(value, selectedColumn);
  };

  const addNewStatus = async () => {
    try {
      const statusPayload = {
        statusID: String(newStatus.statusID), // Ensure StatusID is a string
        statusName: newStatus.statusName,
        notes: newStatus.notes,
      };

      console.log("New status Payload:", statusPayload);
      const addedstatus = await addStatusService(statusPayload);
       setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding status:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add status: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddStatusModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewStatus({
      statusID: "",
      statusName: "",
      notes: "",
    });
  };

  const openEditStatusModal = async (statusID) => {
    try {
      const fetchedStatus = await getStatusById(String(statusID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedStatusID(statusID);
      setNewStatus({
        ...fetchedStatus,
      });
    } catch (error) {
      console.error(
        `Error fetching status with ID ${statusID}:`,
        error.response.data
      );
    }
  };

  const updateExistingStatus = async () => {
    try {
      const statusPayload = { ...newStatus };

      console.log("Updated status Payload:", statusPayload);
      const updatedStatus = await updateStatus(selectedStatusID, statusPayload);
      setUpdate((update) => !update);
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating status with ID ${selectedStatusID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update status: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingStatus = async (statusID) => {
    try {
      await deleteStatus(statusID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(
        `Error deleting status with ID ${statusID}:`,
        error.response.data
      );
      alert(`Failed to delete status: ${error.response.data.title}`);
    }
  };

  const closeAddStatusModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteStatusHandler = (statusID) => {
    if (
      window.confirm(
        `Are you sure you want to delete status with ID ${statusID}?`
      )
    ) {
      deleteExistingStatus(statusID);
    }
  };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">
          Status
        </h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
          <button
            onClick={openAddStatusModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Status
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {status.length > 0 ? (
                  Object.keys(status[0])?.map((op, index) => (
                    <option key={index} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search status..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="mt-8">
          <StatusTable
            status={filteredStatus}
            openEditModal={openEditStatusModal}
            deleteStatus={deleteStatusHandler}
          />
        </div>
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddStatusModal}
        contentLabel={editMode ? "Edit Status" : "Add Status"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Status" : "Add Status"}
        </h2>
        <StatusForm
          formValues={newStatus}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingStatus : addNewStatus}
            className="bg-indigo-600 px-5 text-white py-2 rounded mr-2 hover:bg-indigo-700 transition-all duration-200"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddStatusModal}
            className="border border-indigo-600 px-5 text-indigo-600 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>  
  );
};

export default AddStatus;
