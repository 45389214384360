import apiClient from './apiClient';
import { logAction } from '../utils/logger';
const addPermission = async (UsersData) => {
  try {
    const response = await apiClient.post('/api/Permission', UsersData);
    if(response){
      logAction("Created Permission","Permission",`Created the following Permission ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in Permission:', error.response.data);
    throw error;
  }
};

const getPermisionById = async (id) => {
  try {
    const response = await apiClient.get(`/api/Permission/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching Permission with ID ${id}:`, error.response.data);
    throw error;
  }
};



const updatePermission = async (id, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Permission/${id}`, updatedData);
    if(response){
      logAction("Update Permission","Permission",`Update the following Permission ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating Permissions with ID ${id }:`, error.response.data);
    throw error;
  }
};

const deletePermission = async (id) => {
  try {
    const response = await apiClient.delete(`/api/Permission/${id}`);
    if(response){
      logAction("Deleted Permission","Permission",`Deleted the following Permission ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    console.error(`Error deleting Permissions with ID ${id}:`, error.response.data);
    throw error;
  }
};

const getPermissions = async () => {
  try {
    const response = await apiClient.get('/api/Permission');
    return response.data;
  } catch (error) {
    console.error('Error fetching Permissions:', error.response.data);
    throw error;
  }
};

export { addPermission,getPermissions,getPermisionById,updatePermission,deletePermission };
