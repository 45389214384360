import React, { useState, useEffect, useMemo } from 'react';
import Table from '../common/Table';
import Dropdown from '../Dropdown/dropDown';
import { getUsersByUsername } from '../../services/usersS';

const ParticipantActivityTable = ({ ParticipantActivity, updateParticipantActivity, deleteParticipantActivity }) => {

  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;


  const columns = [
    { Header: 'Admin Number', accessor: 'adminNumber' },
    { Header: 'Student Name', accessor: 'studentName' },
    { Header: 'Award Center', accessor: 'awardCenter'},
    { Header: 'Award Level', accessor: 'awardLevel' },
    { Header: 'Activity Name', accessor: 'activityName' },
    { Header: 'Medical Form', accessor: 'medicalForm' },
  ];

  const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName);
        } else {
          console.warn("No institution name found in user data");
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserInstitution();
  }, []);

  const filteredParticipantActivity = useMemo(() => {
    if (!loggedInUser || !ParticipantActivity) return [];
    if (loggedInUser.role.name === "Award Leader" && userInstitution) {
      return ParticipantActivity.filter(activity => activity.awardCenter === userInstitution);
    }
    return ParticipantActivity;
  }, [loggedInUser, ParticipantActivity, userInstitution]);


  const renderRowActions = (activity) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>updateParticipantActivity(activity)}
      onDelete={()=>deleteParticipantActivity(activity.id)}
    />
  </div>
  );

  return (
    <div>
      <Table
        columns={columns}
        data={filteredParticipantActivity}
        renderRowActions={renderRowActions}
      />

    </div>
  );
};

export default ParticipantActivityTable;
