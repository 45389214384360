import apiClient from './apiClient';
import { logAction } from '../utils/logger';
const addRole = async (UsersData) => {
  try {
    const response = await apiClient.post('/api/Roles', UsersData);
    if(response){
      logAction("Created Role","Role",`Created the following Role ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in addUsers:', error.response.data);
    throw error;
  }
};

const getRoleById = async (id) => {
  try {
    const response = await apiClient.get(`/api/Roles/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching Users with ID ${id}:`, error.response.data);
    throw error;
  }
};



const updateRole = async (username, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Roles/${username}`, updatedData);
    if(response){
      logAction("Updated Role","Role",`Updated the following Role ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating Roles with ID ${username }:`, error.response.data);
    throw error;
  }
};

const deleteRole = async (username) => {
  try {
    const response = await apiClient.delete(`/api/Roles/${username}`);
    if(response){
      logAction("Deleted Role","Role",`Deleted the following Role ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    console.error(`Error deleting Roles with ID ${username}:`, error.response.data);
    throw error;
  }
};

const getRoles = async () => {
  try {
    const response = await apiClient.get('/api/Roles');
    return response.data;
  } catch (error) {
    console.error('Error fetching Roles:', error.response.data);
    throw error;
  }
};

const addPermissionToRole = (data) => {
  return apiClient.post(`/api/PermissionRoles`,data);
};

 const removePermissionFromRole = (roleId, permissionId) => {
  return apiClient.delete(`/api/PermissionRoles/${permissionId}/${roleId}`);
};


export { addRole,getRoles,getRoleById,deleteRole,updateRole ,addPermissionToRole,removePermissionFromRole};
