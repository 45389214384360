import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import FeedbackForm from '../../components/forms/feedbackF';
import FeedbackTable from '../../components/tables/feedbackT';
import { addFeedback as addFeedbackService, getFeedbackById, updateFeedback, deleteFeedback, getFeedback } from '../../services/feedbackS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');
const AddFeedback = () => {
  const [feedback, setFeedbacks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFeedback, setNewFeedback] = useState({ 
      
      respondentID: '',
      titleName: '',
      description: '',
      respondentType: '',
      phoneNo: '',
      respondentEmail: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("titleName");
  const [filteredFeedbacks, setFilteredFeedbacks] = useState(feedback);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const fetchedFeedbacks = await getFeedback();
        setFeedbacks(fetchedFeedbacks);
        setFilteredFeedbacks(fetchedFeedbacks); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching Feedbacks:', error.response.data);
      }
    };

    fetchFeedbacks();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewFeedback = async () => {
    try {
      const feedbackPayload = { ...newFeedback };
      await addFeedbackService(feedbackPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error('Error adding respondent:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add respondent: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddFeedbackModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewFeedback({
      respondentID: '',
      titleName: '',
      description: '',
      respondentType: '',
      phoneNo: '',
      respondentEmail: '',
    });
  };

  const openEditFeedbackModal = async (respondentID) => {
    try {
      console.log('Fetching respondent with ID:', respondentID);
      const fetchedFeedback = await getFeedbackById(respondentID);
      console.log('Fetched Feedback:', fetchedFeedback);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedFeedbackId(respondentID);
      setNewFeedback({
        ...fetchedFeedback,
     
      });
    } catch (error) {
      console.error(`Error fetching respondent with ID ${respondentID}:`, error.response.data);
    }
  };
  
  const updateExistingFeedback = async () => {
    try {
      const feedbackPayload = { ...newFeedback };
  
      console.log('Updated Feedback Payload:', feedbackPayload);
  
      await updateFeedback(selectedFeedbackId, feedbackPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating respondent with ID ${selectedFeedbackId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update respondent: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingFeedback = async (respondentID) => {
    try {
      await deleteFeedback(respondentID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting respondent with ID ${respondentID}:`, error.response.data);
      alert(`Failed to delete respondent: ${error.response.data.title}`);
    }
  };

  const closeAddFeedbackModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteFeedbackHandler = (respondentID) => {
    if (window.confirm(`Are you sure you want to delete respondent with ID ${respondentID}?`)) {
      deleteExistingFeedback(respondentID);
    }
  };

//Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterFeedbacks(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterFeedbacks(searchTerm, value);
  };

  const filterFeedbacks = (searchTerm, column) => {
    const filtered = feedback.filter((feedback) =>
      feedback[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredFeedbacks(filtered);
  };
  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Feedback</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddFeedbackModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Feedback
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {feedback.length > 0 ? (
                Object.keys(feedback[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <FeedbackTable
        feedback={filteredFeedbacks}
        openEditModal={openEditFeedbackModal}
        deleteFeedback={deleteFeedbackHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddFeedbackModal}
      contentLabel={editMode ? "Edit Feedback" : "Add Feedback"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Feedback" : "Add Feedback"}
      </h2>
      <FeedbackForm
        formValues={newFeedback} 
        handleInputChange={handleInputChange} 
        handleDateChange={handleDateChange} 
        errors={errors} 
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingFeedback : addNewFeedback}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddFeedbackModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddFeedback;
