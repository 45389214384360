// src/services/reportsServices/reportParticipantAwards.js
import apiClient from '../apiClient';
import { formatDate } from './formatDate';

const PARTICIPANT_AWARDS_API_URL = '/api/ParticipantAward';

export const fetchParticipantAwardsData = async () => {
  try {
    const response = await apiClient.get(PARTICIPANT_AWARDS_API_URL);
    const formattedData = response.data.map(item => ({
      ...item,
      startDate: formatDate(item.startDate),
      expectedEndDate: formatDate(item.expectedEndDate),
      actualEndDate: formatDate(item.actualEndDate),
    }));
    return formattedData;
  } catch (error) {
    console.error('Error fetching participant awards data:', error);
    throw error;
  }
};

export const participantAwardsHeaders = [
  { Header: 'Award ID', accessor: 'awardID' },
  { Header: 'Admin Number', accessor: 'adminNumber' },
  { Header: 'Student Name', accessor: 'studentName' },
  { Header: 'Institution Name', accessor: 'institutionName' },
  { Header: 'Level Name', accessor: 'levelName' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'Expected End Date', accessor: 'expectedEndDate' },
  { Header: 'Actual End Date', accessor: 'actualEndDate' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Notes', accessor: 'notes' }
];