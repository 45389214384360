import React, {useState, useEffect, useMemo} from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
import { getUsersByUsername } from '../../services/usersS';

const columns = [
  { Header: 'ID', accessor: 'projectID' },
  { Header: 'Name', accessor: 'projectName' },
  { Header: 'Award Center', accessor: 'institutionName' },
  { Header: 'Award Center 2', accessor: 'institutionName2' },
  { Header: 'Award Center 3', accessor: 'institutionName3' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'End Date', accessor: 'endDate' },
  { Header: 'Cost', accessor: 'cost' },
  { Header: 'Sub County', accessor: 'subCounty' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Description', accessor: 'description' },
  { Header: "Coordinator's Name", accessor: 'coordinator' },
  { Header: 'Notes', accessor: 'notes' },
];

const ProjectTable = ({ projects, openEditModal, deleteProject }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;

  
  const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName);
        } else {
          console.warn("No institution name found in user data");
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

  // Fetch user's institution when component mounts
  useEffect(() => {
    fetchUserInstitution();
  }, []);

 // Filter projects based on user role and institution
 const filteredProjects = useMemo(() => {
  if (!loggedInUser || !projects) return [];
  if (loggedInUser.role.name === "Award Leader" && userInstitution) {
    // Filter projects where institutionName, institutionName2, or institutionName3 matches user's institution
    return projects.filter(project => 
      project.institutionName === userInstitution ||
      project.institutionName2 === userInstitution ||
      project.institutionName3 === userInstitution
    );
  }
  // For other roles, show all projects
  return projects;
}, [loggedInUser, projects, userInstitution]);


  const renderRowActions = (project) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(project)} onDelete={()=>deleteProject(project)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={filteredProjects}
      renderRowActions={renderRowActions}
    />
  );
};

export default ProjectTable;
