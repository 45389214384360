import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../../styles/customStyles";
import UserForm from "../../../components/forms/usersF";
import UserTable from "./Users/userTable";
import Roles from "./Roles/roles";
import Permissions from "./Permissions";
import {
  addUsers as addUsersService,
  updateUsers,
  deleteUsers,
  getUsers,
} from "../../../services/usersS";
import { addRole, getRoles } from "../../../services/rolesS";
import { getPermissions } from "../../../services/permissionsS";
Modal.setAppElement("#root");

const AddUser = () => {
  const [tab, setTab] = useState("users"); // State for tab switching
  const [Users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    name: "",
    roleID: "",
    gender: "",
    idNo: "",
    PhoneNo: "",
    email: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredUsers, setFilteredUsers] = useState(Users);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching Users:", error.response.data);
      }
    };

    fetchUsers();
    fetchRoles();
    fetchPermissions();
  }, [update]);

  const triggerUpdate = () => {
    return setUpdate((prev) => !prev);
  };
  const fetchRoles = async () => {
    try {
      const fetchedRoles = await getRoles();
      setRoles(fetchedRoles);
      // setFilteredUsers(getRoles);
    } catch (error) {
      console.error("Error fetching Users:", error.response.data);
    }
  };
  const fetchPermissions = async () => {
    try {
      const fetchedPermissions = await getPermissions();
      setPermissions(fetchedPermissions);
      // setFilteredUsers(getRoles);
    } catch (error) {
      console.error("Error fetching Users:", error.response.data);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewUser = async () => {
    try {
      const UserPayload = { ...newUser };
      await addUsersService(UserPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding User:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add User: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddUserModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewUser({
      username: "",
      name: "",
      roleID: "",
      gender: "",
      idNo: "",
      PhoneNo: "",
      email: "",
    });
  };

  const updateExistingUser = async () => {
    try {
      const UserPayload = { ...newUser };
      await updateUsers(selectedUserId, UserPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating User with ID ${selectedUserId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update User: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingUser = async (username) => {
    try {
      await deleteUsers(username);
      setUpdate((prev) => !prev);
    } catch (error) {
      console.error(
        `Error deleting User with ID ${username}:`,
        error.response.data
      );
      alert(`Failed to delete User: ${error.response.data.title}`);
    }
  };

  const closeAddUserModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteUserHandler = (username) => {
    if (
      window.confirm(
        `Are you sure you want to delete User with ID ${username}?`
      )
    ) {
      deleteExistingUser(username);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterUsers(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    setFilteredUsers(searchTerm, value);
  };

  const filterUsers = (searchTerm, column) => {
    const filtered = Users.filter((user) =>
      user[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
  const deleteRole = () => {};
  const deletePermission = () => {};
  const onAddPermission = () => {};

  // Tab switching
  const renderContent = () => {
    if (tab === "users") {
      return (
        <div className="w-full">
          <form className="flex flex-col md:flex-row justify-center  gap-4 w-full mb-5 md:w-auto">
            <div className="relative flex items-center w-full md:w-auto">
              <select
                className="p-3 pr-10 py-2 text-base border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
                defaultValue=""
                aria-label="Filter by">
                {[
                  "",
                  "Award Center Name",
                  "Role",
                  "Username",
                  "Email",
                  "Phone",
                ].map((p, index) => (
                  <option key={index} value={p}>
                    {p || "Select an option"}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative flex items-center w-full md:w-auto">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="rounded-full border  py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500  bg-gray-100 text-gray-700 placeholder-gray-500 shadow-sm"
              />
              <button className="absolute right-3 text-gray-400 hover:text-gray-600 text-lg">
                <FiSearch />
              </button>
            </div>
          </form>
          <div className="w-full">
            <UserTable
              Users={filteredUsers}
              openEditModal={openAddUserModal}
              deleteUser={deleteUserHandler}
              onAddPermission={onAddPermission}
            />
          </div>
        </div>
      );
    } else if (tab === "roles") {
      return (
        <Roles
          roles={roles}
          openEditModal={false}
          deleteRole={deleteRole}
          update={triggerUpdate}
        />
      );
    } else if (tab === "permissions") {
      return (
        <Permissions
          permissions={permissions}
          deletePermission={deletePermission}
          update={triggerUpdate}
        />
      );
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-Satoshi font-semibold text-indigo-700 p-4 mb-6 rounded-lg">
        Manage Users
      </h2>
      <div className="flex space-x-4 mb-4">
        <button
          className={`${
            tab === "users"
              ? "text-indigo-600 border-b-2 border-indigo-600"
              : ""
          } p-2 rounded`}
          onClick={() => setTab("users")}>
          Users
        </button>
        <button
          className={`${
            tab === "roles"
              ? "text-indigo-600 border-b-2 border-indigo-600"
              : ""
          } p-2 rounded`}
          onClick={() => setTab("roles")}>
          Roles
        </button>
        <button
          className={`${
            tab === "permissions"
              ? "text-indigo-600 border-b-2 border-indigo-600"
              : ""
          } p-2 rounded`}
          onClick={() => setTab("permissions")}>
          Permissions
        </button>
      </div>
      <div>{renderContent()}</div>

      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddUserModal}
        contentLabel={editMode ? "Edit User" : "Add User"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit User" : "Add User"}
        </h2>
        <UserForm
          formValues={newUser}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingUser : addNewUser}
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddUserModal}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddUser;
