import React, { useState, useEffect, useRef } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import apiClient from "../../services/apiClient";
import { FaPenToSquare } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import Modal from "react-modal";
import { smallFormModal } from "../../styles/smallFormModal";
import { Navigate, Link } from "react-router-dom";
import { getUsersByUsername, updateUsers } from "../../services/usersS";

const Dropdown = ({ onDelete, onEdit, permissions, user, id, username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userToApprove, setUserToApprove] = useState({});
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of the dropdown and modal
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    onEdit(id);
    setIsOpen(false); // Close dropdown after action
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up

    onDelete(id);
    setIsOpen(false); // Close dropdown after action
  };
  const handleAproval = async () => {
    try {
      await updateUsers(username, { approvalStatus: 1 });
      setIsOpen(false); // Close dropdown after action
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  let fetchedUser = async () => {
    try {
      const user = await getUsersByUsername(username);
      setUserToApprove(user);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    if (username) {
      (async () => {
        await fetchedUser();
      })();
    }
  }, []);

  return (
    <div className="text-left relative">
      <div>
        <div
          type="button"
          className="inline-flex justify-center gap-x-1.5 rounded-[5px] cursor-pointer px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}>
          <SlOptionsVertical />
        </div>
      </div>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleDropdown}
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          style={smallFormModal}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button">
          <div
            className="py-1 z-50 gap-3 w-auto p-3 flex flex-col items-center justify-center cursor-pointer"
            role="none"
            onClick={(e) => e.stopPropagation()} // Prevent modal clicks from closing dropdown
          >
            {/* Permissions */}
            {permissions && (
              <div className="flex justify-around w-full gap-2">
                <Link
                  to={`/settings/users/${id}`}
                  className="text-[18px] font-myfont">
                  Permissions
                </Link>
              </div>
            )}
            {user && userToApprove.approvalStatus == 0 && (
              <div
                onClick={handleAproval}
                className="flex justify-around w-full gap-2">
                <div className="font-myfont tracking-[1px] block py-2 px-4 rounded bg-blue-200">
                  Approve user
                </div>
              </div>
            )}

            {/* Edit */}
            <div
              onClick={handleEdit}
              className="flex justify-around w-full gap-2">
              <FaPenToSquare className="text-[20px] font-myfont text-secondary" />
              <span className="text-[18px] font-myfont">Edit</span>
            </div>

            {/* Delete */}
            <div
              onClick={handleDelete}
              className="flex justify-around w-full gap-1">
              <MdDelete className="text-[20px] font-myfont text-secondary" />
              <span className="text-[18px] font-myfont">Delete</span>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Dropdown);
