import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import { getUsersByUsername,updateUsers } from "../../services/usersS";
const Profile = () => {
  // Initial state with placeholder user data
  const [profile, setProfile] = useState({});

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      let userDetails = localStorage.getItem("user");
      const parsedUser = JSON.parse(userDetails);
      
      let user = await getUsersByUsername(parsedUser.user.username);
      console.log(user)
      localStorage.setItem("old-user", JSON.stringify(user));
      setProfile(user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };
  let initialUserProfile = localStorage.getItem("old-user");


const handleProfileUpdate = (e) => {
  e.preventDefault()
 let updatedProfile =  updateUsers(profile.username,profile)
 setProfile(updatedProfile)
 localStorage.setItem("old-user", JSON.stringify(updatedProfile));
}
console.log(profile)
  return (
    <Layout>
      <div className="w-full bg-white bg-opacity-50 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl text-center font-myfont font-semibold text-gray-800 mb-4">
          Account Information
        </h2>
        <div className="flex justify-center mt-4 w-full mb-4">
          <img
            className="h-32 w-32 rounded-full border-4 border-white shadow-md"
            src="/assets/profile.jpeg"
            alt="Profile"
          />
        </div>
        <form onSubmit={handleProfileUpdate} className="space-y-4 grid grid-cols-1 md:grid-cols-2 gap-4 pt-5 pb-4">
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600 bg-transparent">
              Username:
            </label>
            <input
              type="text"
              name="username"
              disabled
              value={profile.username}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Names:</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={profile.name}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Role:</label>
            <input
              type="role"
              disabled
              name="role"
              value={profile.role?.name}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Phone */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">ID No:</label>
            <input
              type="text"
              name="idNo"
              onChange={handleChange}
              value={profile.idNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Job Title */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Phone No:</label>
            <input
              type="text"
              name="gender"
              onChange={handleChange}
              value={profile.phoneNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Location */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Email:</label>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={profile.email}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <button
              disabled={initialUserProfile == JSON.stringify(profile)}
              className={`mt-6 bg-indigo-600 text-white py-2 px-4 rounded 
              ${
                initialUserProfile == JSON.stringify(profile)
                  ? "bg-gray-400 cursor-not-allowed text-gray-700"
                  : "bg-blue-500 text-white cursor-pointer"
              }`}>
              Update
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Profile;
