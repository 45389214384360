import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import TestimonialsForm from "../../components/forms/testimonialsF";
import TestimonialsTable from "../../components/tables/testimonialsT";
import {
  addTestimonial as addTestimonialService,
  getTestimonialById,
  updateTestimonial,
  deleteTestimonial,
  getTestimonial,
} from "../../services/testimonialsS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTestimonial, setNewTestimonial] = useState({
    userID: "",
    Username: "",
    Role: "",
    Description: "",
    Notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("Username");
  const [filteredTestimonials, setFilteredTestimonials] =
    useState(testimonials);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const fetchedTestimonials = await getTestimonial();
        console.log("Fetched Testimonials:", fetchedTestimonials); // Log the fetched data
        setTestimonials(fetchedTestimonials);
        setFilteredTestimonials(fetchedTestimonials);
      } catch (error) {
        console.error(
          "Error fetching Testimonials:",
          error.response?.data || error.message
        );
      }
    };

    fetchTestimonials();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTestimonial((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTestimonial = async () => {
    try {
      const TestimonialPayload = { ...newTestimonial };

      console.log("New Testimonial Payload:", TestimonialPayload);
      const addedTestimonial = await addTestimonialService(TestimonialPayload);
      setTestimonials((prev) => [...prev, addedTestimonial]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        "Error adding Testimonial:",
        error.response?.data || error.message
      );
      setErrors(error.response?.data?.errors || {});
      alert(
        `Failed to add Testimonial: ${
          error.response?.data?.title
        }\nDetails: ${JSON.stringify(error.response?.data?.errors, null, 2)}`
      );
    }
  };

  const openAddTestimonialModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTestimonial({
      userID: "",
      Username: "",
      Role: "",
      Description: "",
      Notes: "",
    });
  };

  const openEditTestimonialModal = async (id) => {
    try {
      console.log("Fetching Testimonial with ID:", id);
      const fetchedTestimonial = await getTestimonialById(id);
      console.log("Fetched Testimonial:", fetchedTestimonial);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTestimonialId(id);
      setNewTestimonial({
        ...fetchedTestimonial,
      });
    } catch (error) {
      console.error(
        `Error fetching Testimonial with ID ${id}:`,
        error.response?.data || error.message
      );
    }
  };

  const updateExistingTestimonial = async () => {
    try {
      const TestimonialPayload = { ...newTestimonial };

      console.log("Updated Testimonial Payload:", TestimonialPayload);

      const updatedTestimonial = await updateTestimonial(
        selectedTestimonialId,
        TestimonialPayload
      );
    setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating Testimonial with ID ${selectedTestimonialId}:`,
        error.response?.data || error.message
      );
      setErrors(error.response?.data?.errors || {});
      alert(
        `Failed to update Testimonial: ${
          error.response?.data?.title
        } ${JSON.stringify(error.response?.data?.errors, null, 2)}`
      );
    }
  };

  const deleteExistingTestimonial = async (userID) => {
    try {
      await deleteTestimonial(userID);
      setTestimonials((prev) => prev.filter((inst) => inst.userID !== userID));
    } catch (error) {
      console.error(
        `Error deleting Testimonial with ID ${userID}:`,
        error.response?.data || error.message
      );
      alert(`Failed to delete Testimonial: ${error.response?.data?.title}`);
    }
  };

  const closeAddTestimonialModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTestimonialHandler = (userID) => {
    if (
      window.confirm(
        `Are you sure you want to delete Testimonial with ID ${userID}?`
      )
    ) {
      deleteExistingTestimonial(userID);
    }
  };

   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTestimonials(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTestimonials(searchTerm, value);
  };

  const filterTestimonials = (searchTerm, column) => {
    const filtered = testimonials.filter((testimonial) =>
        testimonial[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTestimonials(filtered);
  };

  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Testimonials</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddTestimonialModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Add Testimonial
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {testimonials.length > 0 ? (
                Object.keys(testimonials[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <TestimonialsTable
        testimonials={filteredTestimonials}
        openEditModal={openEditTestimonialModal}
        deleteTestimonial={deleteTestimonialHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddTestimonialModal}
      contentLabel={editMode ? "Edit Testimonial" : "Add Testimonial"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Testimonial" : "Add Testimonial"}
      </h2>
      <TestimonialsForm
        formValues={newTestimonial}
        handleInputChange={handleInputChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingTestimonial : addNewTestimonial}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddTestimonialModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddTestimonial;
