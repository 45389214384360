import apiClient from '../apiClient';

const PARTICIPANT_ACTIVITY_API_URL = '/api/ParticipantActivity';

export const fetchParticipantActivityData = async () => {
  try {
    const response = await apiClient.get(PARTICIPANT_ACTIVITY_API_URL);
    return response.data; // No date formatting needed in this case
  } catch (error) {
    console.error('Error fetching participant activity data:', error);
    throw error;
  }
};

export const participantActivityHeaders = [
  { Header: 'Admin Number', accessor: 'adminNumber' },
  { Header: 'Student Name', accessor: 'studentName' },
  { Header: 'Center', accessor: 'awardCenter' },
  { Header: 'Award Level', accessor: 'awardLevel' },
  { Header: 'Activity Name', accessor: 'activityName' },
];
