import apiClient from './apiClient';
import { logAction } from '../utils/logger';
export const addParticipant = async (participantData) => {
  try {
    const response = await apiClient.post('/api/Participants', participantData);
    if(response){
      logAction("Created Participant","Participant",`Created the following Participant ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in addParticipant:', error.response?.data || error.message);
    throw error;
  }
};

// Function to get a participant by ID
export const getParticipantById = async (participantId) => {
  try {
    const response = await apiClient.get(`/api/Participants/${participantId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching participant with ID ${participantId}:`, error.response?.data || error.message);
    throw error;
  }
};

// Function to update an existing participant
export const updateParticipant = async (adminNumber, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Participants/${adminNumber}`, updatedData);
    if(response){
      logAction("Updated Participant","Participant",`Updated the following Participant ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating participant with adminNumber ${adminNumber}:`, error.response?.data || error.message);
    throw error;
  }
};

// Function to delete a participant
export const deleteParticipant = async (adminNumber) => {
  try {
    const response = await apiClient.delete(`/api/Participants/${adminNumber}`);
    if(response){
      logAction("Deleted Participant","Participant",`Deleted the following Participant ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    console.error(`Error deleting participant with adminNumber ${adminNumber}:`, error.response?.data || error.message);
    throw error;
  }
};

// Function to get all participants
export const getParticipant = async () => {
  try {
    const response = await apiClient.get('/api/Participants');
    return response.data;
  } catch (error) {
    console.error('Error fetching participants:', error.response?.data || error.message);
    throw error;
  }
};
