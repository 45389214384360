import React from "react";
import { useNavigate } from "react-router-dom";
import { Bell, Search, User, Lock, LogOut } from "lucide-react";
import ResetPasswordInDashboard from "../../pages/ResetPasswordInDashboard";
import { useState } from "react";

const Navbar = ({ dropdownRef, toggleDropdown, breadcrum, handleLogout, profileImage }) => {
  const [changePassword,setChangePassword]=useState(false)
  const navigate = useNavigate();



  const handleChangePassword = ()=>setChangePassword(true)

  return (
    <div className="sticky top-0 z-50 w-full bg-white drop-shadow-2xl">
      <div className="w-full px-4 sm:px-6 lg:px-8 py-6"> {/* Increased py-3 to py-6 */}
        <div className="flex justify-between items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <img
              src="/assets/newlogo.png"
              alt="Logo"
              className="h-14 w-full"  
            />{/* Increased h-12 to h-16 */}
          </div>

          {/* Title */}
          <h1 className="text-2xl text-main font-black subpixel-antialiased tracking-wide text-center flex-grow px-4">
            Project Management Dashboard
          </h1>  {/* Changed text-2xl to text-3xl */}

          {/* Search, Notification, and Profile */}
          <div className="flex items-center space-x-4">
            {/* Search Bar */}
            <div className="hidden md:flex items-center bg-gray-100 rounded-full px-3 py-2 w-58">
              <input
                type="text"
                placeholder="Search..."
                className="bg-transparent outline-none text-sm w-full"
              />
              <Search className="text-gray-400 w-5 h-5 ml-2 flex-shrink-0" />
            </div>

            {/* Notifications */}
            <button className="text-gray-600 hover:text-main relative">
              <Bell className="w-7 h-7" />  {/* Increased icon size */}
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                3
              </span>
            </button>

            {/* User Profile Dropdown */}
            <div className="relative">
              <div
                ref={dropdownRef}
                className="rounded-full flex items-center cursor-pointer"
                onClick={toggleDropdown}
              >
                <img
                  className="h-12 w-12 rounded-full border border-gray-200 shadow-sm"  
                  src={profileImage || "/assets/profile.jpeg"}
                  alt="Profile"
                />{/* Increased h-10 w-10 to h-12 w-12 */}
              </div>
              {breadcrum ?(
                <div
                ref={dropdownRef}
               
                  className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1" role="none">
    <div
      onClick={() => navigate("/profile")}
      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
      role="menuitem"
    >
      <User className="w-4 h-4 mr-2" />
      Profile
    </div>
    <div
      onClick={handleChangePassword}
      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
      role="menuitem"
    >
      <Lock className="w-4 h-4 mr-2" />
      Change password
    </div>
    <button
      onClick={handleLogout}
      className="flex items-center w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
      tabIndex="-1"
    >
      <LogOut className="w-4 h-4 mr-2" />
      Logout
    </button>
  </div>
                </div>
              ): null}
            </div>
          </div>
        </div>
      </div>
      <ResetPasswordInDashboard editModal={changePassword}  closeEdit={()=>setChangePassword(false)}  />
    </div>
  );
};

export default Navbar;
