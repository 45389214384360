import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import {customStyles} from '../../styles/customStyles'
import DonorForm from '../../components/forms/donorsF';
import DonorTable from '../../components/tables/donorsT';
import { addDonor as addDonorService, getDonorById, updateDonor, deleteDonor, getDonor } from '../../services/donorsS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddDonor = () => {
    const [Donors, setDonors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDonor, setNewDonor] = useState({
        DonorID: '',
        DonorName: '',
        Contact: '',
        Notes: '',
    });
    const [selectedColumn, setSelectedColumn] = useState("DonorName");
    const [filteredDonors, setFilteredDonors] =
      useState(Donors);
    const [searchTerm, setSearchTerm] = useState("");
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [selectedDonorId, setSelectedDonorId] = useState(null);

    useEffect(() => {
        const fetchDonors = async () => {
            try {
                const fetchedDonors = await getDonor();
                setDonors(fetchedDonors);
                setFilteredDonors(fetchedDonors);
            } catch (error) {
                console.error('Error fetching Donors:', error.response.data);
            }
        };

        fetchDonors();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDonor((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const addNewDonor = async () => {
        try {
            const DonorPayload = { ...newDonor };

            console.log('New Donor Payload:', DonorPayload);
            const addedDonor = await addDonorService(DonorPayload);
            setDonors((prev) => [...prev, addedDonor]);
            setIsModalOpen(false);
            setErrors({});
        } catch (error) {
            console.error('Error adding Donor:', error.response.data);
            setErrors(error.response.data.errors || {});
            alert(`Failed to add Donor: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
        }
    };

    const openAddDonorModal = () => {
        setEditMode(false);
        setIsModalOpen(true);
        setNewDonor({
            DonorID: '',
            DonorName: '',
            Contact: '',
            Notes: '',
        });
    };

    const openEditDonorModal = async (Donor) => {
        try {
            console.log('Fetching Donor with ID:', Donor.DonorID);
            const fetchedDonor = await getDonorById(Donor.DonorID);
            console.log('Fetched Donor:', fetchedDonor); // Log the fetched Donor
            setEditMode(true);
            setIsModalOpen(true);
            setSelectedDonorId(Donor.DonorID);
            setNewDonor({
                ...fetchedDonor,
            });
        } catch (error) {
            console.error(`Error fetching Donor with ID ${Donor.DonorID}:`, error.response.data);
        }
    };

    const updateExistingDonor = async () => {
        try {
            const DonorPayload = { ...newDonor };

            console.log('Updated Donor Payload:', DonorPayload);

            const updatedDonor = await updateDonor(selectedDonorId, DonorPayload);
            setDonors((prev) => prev.map(inst => (inst.DonorID === selectedDonorId ? updatedDonor : inst)));
            setIsModalOpen(false);
            setErrors({});
        } catch (error) {
            console.error(`Error updating Donor with ID ${selectedDonorId}:`, error.response.data);
            setErrors(error.response.data.errors || {});
            alert(`Failed to update Donor: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
        }
    };

    const deleteExistingDonor = async (DonorID) => {
        try {
            await deleteDonor(DonorID);
            setDonors((prev) => prev.filter(inst => inst.DonorID !== DonorID));
        } catch (error) {
            console.error(`Error deleting Donor with ID ${DonorID}:`, error.response.data);
            alert(`Failed to delete Donor: ${error.response.data.title}`);
        }
    };

    const closeAddDonorModal = () => {
        setIsModalOpen(false);
        setEditMode(false);
        setErrors({});
    };

    const deleteDonorHandler = (DonorID) => {
        if (window.confirm(`Are you sure you want to delete Donor with ID ${DonorID}?`)) {
            deleteExistingDonor(DonorID);
        }
    };

     //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterDonors(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterDonors(searchTerm, value);
  };

  const filterDonors = (searchTerm, column) => {
    const filtered = Donors.filter((participant) =>
      participant[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredDonors(filtered);
  };

    return (
        <Layout>
        <div className="bg-white rounded-lg shadow-lg">
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-semibold text-gray-800">Donors</h1>
          </div>
      
          <div className="p-6">
            <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
              <button
                onClick={openAddDonorModal}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
              >
                <FaPlus className="mr-2 h-4 w-4" />
                <span>Add Donor</span>
              </button>
      
              <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
                <div className="relative flex-1">
                  <select
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                    value={selectedColumn}
                    onChange={handleFilterParameterChange}
                  >
                    {Donors.length > 0 ? (
                      Object.keys(Donors[0])?.map((op) => (
                        <option key={op} value={op}>
                          {splitWordFunc(op)}
                        </option>
                      ))
                    ) : (
                      <option>No filter property</option>
                    )}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <FaFilter className="h-4 w-4 text-gray-400" />
                  </div>
                </div>
      
                <div className="relative flex-1">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <FiSearch className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>
            </div>
      
            <DonorTable
              Donors={filteredDonors}
              openEditModal={openEditDonorModal}
              deleteDonor={deleteDonorHandler}
            />
          </div>
      
          <Modal
            style={customStyles}
            isOpen={isModalOpen}
            onRequestClose={closeAddDonorModal}
            contentLabel={editMode ? "Edit Donor" : "Add Donor"}
          >
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              {editMode ? "Edit Donor" : "Add Donor"}
            </h2>
            <DonorForm
              formValues={newDonor}
              handleInputChange={handleInputChange}
              errors={errors}
            />
            <div className="flex justify-end mt-4">
              <button
                onClick={editMode ? updateExistingDonor : addNewDonor}
                className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
              >
                {editMode ? "Update" : "Save"}
              </button>
              <button
                onClick={closeAddDonorModal}
                className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      </Layout>
      
    );
};

export default AddDonor;
