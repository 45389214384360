import React from "react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "lucide-react";

const LLCSetupPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-4">
      <div className="max-w-3xl w-full space-y-8">
        {/* Logo Section */}
        <div className="flex justify-center">
          <img
            src="/assets/newlogo.png"
            alt="Logo"
            className="h-auto w-48 md:w-60"
          />
        </div>

        {/* Main Content Card */}
        <div className="bg-white rounded-lg shadow-lg p-8 w-full">
          {/* Header */}
          <div className="text-center space-y-2 mb-6">
            <h1 className="text-2xl md:text-3xl font-bold text-primary">
              Setting up your profile on President Awards Kenya
            </h1>
            <p className="text-base md:text-lg text-gray-600">
              Your profile setup is pending approval. Once approved, you'll be able
              to access the full project management dashboard and features.
            </p>
          </div>

          {/* Status Indicator */}
          <div className="flex items-center justify-center mb-6">
            <div className="flex items-center space-x-2 bg-amber-50 text-amber-700 px-4 py-2 rounded-full">
              <div className="w-2 h-2 bg-amber-500 rounded-full animate-pulse" />
              <span className="text-sm font-medium">Pending Approval</span>
            </div>
          </div>

          {/* Logout Button */}
          <div className="flex justify-center">
            <button
              onClick={handleLogout}
              className="w-full max-w-xs flex items-center justify-center space-x-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition-colors duration-200"
            >
              <LogOut className="w-4 h-4" />
              <span>Logout</span>
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center text-sm text-gray-500">
          <p>Need help? Contact our support team</p>
        </div>
      </div>
    </div>
  );
};

export default LLCSetupPage;