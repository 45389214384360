import React, { useState, useEffect } from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';
import { getInstitutions } from '../../services/awardCenterS'
import { getUsers } from '../../services/usersS';
import { getUsersByUsername } from '../../services/usersS';


const AjApprovalForm = ({ formValues, setFormValues, handleInputChange,handleFileChange, handleDateChange, errors }) => {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLeaders, setLoadingLeaders] = useState(true);
  const [awardLeaders, setAwardLeaders] = useState([]);

  const handleCheckboxChange = (e) => {
    setFormValues({
      ...formValues,
      consent: e.target.checked,
    });
  };

 // Fetch Institutions in alphabetical order
 useEffect(() => {
  const fetchData = async () => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
    
    try {
      if (loggedInUser?.role.name === "Award Leader") {
        // Fetch only the institution of the logged-in Award Leader
        const userData = await getUsersByUsername(loggedInUser.username);
        
        if (userData?.awardCenter?.institutionName) {
          // Set only the Award Leader's institution
          setInstitutions([userData.awardCenter]); // Wrap in an array for consistency
        } else {
          console.warn("No institution found for the logged-in user");
        }
      } else {
        // Fetch all institutions for other roles
        const fetchedInstitutions = await getInstitutions();
        const sortedInstitutions = fetchedInstitutions.sort((a, b) =>
          a.institutionName.localeCompare(b.institutionName)
        );
        setInstitutions(sortedInstitutions);
      }
    } catch (error) {
      console.error("Error fetching institutions:", error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []);

  const formatDate = (date) => {
    return date instanceof Date 
      ? date.toISOString().split('T')[0]
      : '';
  };

  const fetchLeaders = async () => {
    try {
      const users = await getUsers();
      const filteredLeaders = users.filter(user => user?.role.name == "Award Leader"); 
      setAwardLeaders(filteredLeaders);
    } catch (error) {
      console.error('Error fetching award leaders:', error);
    } finally {
      setLoadingLeaders(false);
    }
  };
  // Fetch Award Leaders (users with roleID=3)
  useEffect(() => {
    fetchLeaders();
  }, []);
  return (
    <form className={styles.form}>
      <div className="space-y-4">

        <div>
          <label htmlFor="awardCentre">Award Center:</label>
          {loading ? (
            <p>Loading award centers...</p>
          ) : (
            <select
              name="awardCentre"
              value={formValues.awardCentre}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Center</option>
              {institutions.map((institution) => (
                <option key={institution.institutionID} value={institution.awardCentre}>
                  {institution.institutionName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="region">Region:</label>
          <select className="w-full p-2 border border-gray-300 rounded"
            name="region"
            value={formValues.region}
            onChange={handleInputChange}
          >
            <option value="" disabled>Select a region</option>
            <option value="Central">Central</option>
            <option value="Coast">Coast</option>
            <option value="Lower Eastern">Lower Eastern</option>
            <option value="Nairobi">Nairobi</option>
            <option value="North Eastern">North Eastern</option>
            <option value="North Rift">North Rift</option>
            <option value="Nyanza">Nyanza</option>
            <option value="South Rift">South Rift</option>
            <option value="Upper Eastern">Upper Eastern</option>
            <option value="Western">Western</option>
          </select>
          {errors.region && <p className="text-red-500">{errors.region[0]}</p>}
        </div>

        <div>
          <label htmlFor="awardLeader">Award Leader:</label>
          {loadingLeaders ? (
            <p>Loading award leaders...</p>
          ) : (
            <select
              name="awardLeader"
              value={formValues.awardLeader}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Leader</option>
              {awardLeaders.map((leader) => (
                <option key={leader.userID} value={leader.name}>
                  {leader.name}
                </option>
              ))}
            </select>
          )}
          {errors.awardLeader && <p className="text-red-500">{errors.awardLeader[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityName">Activity Name:</label>
          <Input
            name="activityName"
            placeholder="Activity Name"
            value={formValues.activityName}
            onChange={handleInputChange}
          />
          {errors.activityName && <p className="text-red-500">{errors.activityName[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityDate">Activity Date:</label>
          <Input
            type="date"
            name="activityDate"
            value={formValues.activityDate}
            onChange={handleDateChange}
          />
          {errors.activityDate && <p className="text-red-500">{errors.activityDate[0]}</p>}
        </div>

        <div>
      <label htmlFor="applyDate">Apply Date:</label>
      <Input
        type="date"
        name="applyDate"
        value={formatDate(formValues.applyDate ? new Date(formValues.applyDate) : new Date())}
        onChange={handleDateChange}
      />
      {errors.applyDate && <p className="text-red-500">{errors.applyDate[0]}</p>}
    </div>


        <div>
          <label htmlFor="participantsNo">No. of Participants:</label>
          <Input
            type="number"
            name="participantsNo"
            placeholder="Participants No"
            value={formValues.participantsNo}
            onChange={handleInputChange}
          />
          {errors.participantsNo && <p className="text-red-500">{errors.participantsNo[0]}</p>}
        </div>
        <div>
          <label htmlFor="uploadForm">Upload Form:</label>
          <Input
            name="uploadForm"
            type="file"
            onChange={handleFileChange}
          />
          {errors.uploadForm && (
            <p className="text-red-500">{errors.uploadForm[0]}</p>
          )}
        </div>
        <div className='flex gap-1'>
          <label htmlFor="consent">Consent:</label>
          <input
            type="checkbox"
            name="consent"
            checked={formValues.consent}
            onChange={handleCheckboxChange}
          />
          {errors.consent && <p className="text-red-500">{errors.consent[0]}</p>}
        </div>


        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default AjApprovalForm;
