import React, { useState, useEffect } from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';
import { getLevels } from '../../services/participantLevelsS';
import { getInstitutions } from '../../services/awardCenterS'
import { getAjApproval } from '../../services/ajsApprovalS'
import { getParticipant } from '../../services/participantS';
import { getUsersByUsername } from '../../services/usersS';


const ParticipantActivityForm = ({ formValues, setFormValues, handleInputChange, errors,handleFileChange }) => {
  const [loading, setLoading] = useState({});
  const [awardLevels, setawardLevels] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [activities, setActivities] = useState([]); // State for activities
  const [participants, setParticipants] = useState([]); // State for all participants
  const [filteredParticipants, setFilteredParticipants] = useState([]); // Filtered participants for selected institution
  

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
      
      try {
        if (loggedInUser?.role.name === "Award Leader") {
          // Fetch only the institution of the logged-in Award Leader
          const userData = await getUsersByUsername(loggedInUser.username);
          
          if (userData?.awardCenter?.institutionName) {
            // Set only the Award Leader's institution
            setInstitutions([userData.awardCenter]); // Wrap in an array for consistency
          } else {
            console.warn("No institution found for the logged-in user");
          }
        } else {
          // Fetch all institutions for other roles
          const fetchedInstitutions = await getInstitutions();
          const sortedInstitutions = fetchedInstitutions.sort((a, b) =>
            a.institutionName.localeCompare(b.institutionName)
          );
          setInstitutions(sortedInstitutions);
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  


   // Fetch Participants
   useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const fetchedParticipants = await getParticipant(); // Fetch all participants
        setParticipants(fetchedParticipants);
      } catch (error) {
        console.error('Error fetching participants:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchParticipants();
  }, []);

// Fetch Activities
useEffect(() => {
  const fetchActivities = async () => {
    setLoading(true);
    try {
      const fetchedActivities = await getAjApproval(); // Fetch activities
      // Filter activities where approval is true
      const approvedActivities = fetchedActivities.filter(activity => activity.approval == true);
      setActivities(approvedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
    } finally {
      setLoading(false);
    }
  };
  fetchActivities();
}, []);

 
   // Fetch award levels
   useEffect(() => {
    const fetchLevels = async () => {
      setLoading(true); 
      try {
        const fetchedLevels = await getLevels();
        setawardLevels(fetchedLevels);
      } catch (error) {
      } finally {
        setLoading(false); 
      }
    };
    fetchLevels();
  }, []);

    // Filter participants based on selected institution (award center)
    useEffect(() => {
      if (formValues.awardCenter) {
        const filtered = participants.filter(participant => participant.institutionName === formValues.awardCenter);
        setFilteredParticipants(filtered);
      }
    }, [formValues.awardCenter, participants]);

    // New function to handle admin number change and auto-fill student name
  const handleAdminNumberChange = (e) => {
    const selectedAdminNumber = e.target.value;
    setFormValues((prevValues) => {
      const newValues = { ...prevValues, adminNumber: selectedAdminNumber };
      
      const selectedParticipant = participants.find(p => p.adminNumber === selectedAdminNumber);
      if (selectedParticipant) {
        newValues.studentName = selectedParticipant.name;
      } else {
        newValues.studentName = '';
      }
      
      return newValues;
    });
  }

  return (
    <form className={styles.form}>
      <div className="space-y-4">
     
      <div>
  <label htmlFor="awardCenter">Award Center:</label>
  {loading ? (
    <p>Loading Award Centers...</p>
  ) : (
    <select
      name="awardCenter"
      value={formValues.awardCenter}
      onChange={handleInputChange}
      className="w-full p-2 border border-gray-300 rounded"
    >
      <option value="">Select Institution</option>
      {institutions.map((institution) => (
        <option key={institution.institutionID} value={institution.institutionName}>
          {institution.institutionName}
        </option>
      ))}
    </select>
  )}
  {errors.institutionID && <p className="text-red-500">{errors.institutionID[0]}</p>}
</div>

      <div>
          <label htmlFor="adminNumber">Admin Number:</label>
          {loading ? (
            <p>Loading Students...</p>
          ) : (
            <select
              name="adminNumber"
              value={formValues.adminNumber}
              onChange={handleAdminNumberChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Student</option>
              {filteredParticipants.map((participant) => (
                <option key={participant.adminNumber} value={participant.adminNumber}>
                  {participant.adminNumber} - {participant.name}
                </option>
              ))}
            </select>
          )}
          {errors.adminNumber && <p className="text-red-500">{errors.adminNumber[0]}</p>}
        </div>
        
        <div>
          <label htmlFor="studentName">Student Name:</label>
          <Input
            name="studentName"
            placeholder="Student Name"
            value={formValues.studentName}
            onChange={handleInputChange}
            readOnly
          />
          {errors.studentName && <p className="text-red-500">{errors.studentName[0]}</p>}
        </div>

        <div>
          <label htmlFor="awardLevel">Award Level:</label>
          {loading ? (
            <p>Loading levels...</p>
          ) : (
            <select
              name="awardLevel"
              value={formValues.awardLevel}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Level</option>
              {awardLevels.map((awardLevels) => (
                <option key={awardLevels.levelID} value={awardLevels.levelName}>
                  {awardLevels.levelName}
                </option>
              ))}
            </select>
          )}
          {errors.awardLevel && <p className="text-red-500">{errors.awardLevel[0]}</p>}
        </div>
 
        <div>
          <label htmlFor="activityName">Activity Name:</label>
          <select
            name="activityName"
            value={formValues.activityName}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select Activity</option>
            {activities.map((activity) => (
              <option key={activity.activityID} value={activity.activityName}>
                {activity.activityName}
              </option>
            ))}
          </select>
          {errors.activityName && <p className="text-red-500">{errors.activityName[0]}</p>}
        </div>

        <div>
          <label htmlFor="medicalForm">Upload Medical Form:</label>
          <Input
            type="file"
            name="medicalForm"
            onChange={handleFileChange}
          />
          {errors.medicalForm && <p className="text-red-500">{errors.medicalForm[0]}</p>}
        </div>

        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
       
      </div>
    </form>
  );
};

export default ParticipantActivityForm;