import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../styles/customStyles";
import UserForm from "../components/forms/usersF";
import UserTable from "../components/tables/usersT";
import {
  addUsers as addUsersService,
  getUsersById,
  updateUsers,
  deleteUsers,
  getUsers,
} from "../services/usersS";
import Layout from "../components/Layout/layout";

Modal.setAppElement("#root");

const AddUser = () => {
  const [Users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    name: "",
    roleID: "",
    gender: "",
    idNo: "",
    PhoneNo: "",
    email: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredUsers, setFilteredUsers] = useState(Users);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching Users:", error.response.data);
      }
    };

    fetchUsers();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewUser = async () => {
    try {
      const UserPayload = { ...newUser };
      await addUsersService(UserPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding User:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add User: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddUserModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewUser({
      username: "",
      name: "",
      roleID: "",
      gender: "",
      idNo: "",
      PhoneNo: "",
      email: "",
    });
  };

  const openEditUserModal = async (username) => {
    try {
      const fetchedUser = await getUsersById(username);
      setUpdate((prev) => !prev);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedUserId(username);
      setNewUser({
        ...fetchedUser,
      });
    } catch (error) {
      console.error(
        `Error fetching User with ID ${username}:`,
        error.response.data
      );
    }
  };

  const updateExistingUser = async () => {
    try {
      const UserPayload = { ...newUser };
      await updateUsers(selectedUserId, UserPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating User with ID ${selectedUserId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update User: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingUser = async (username) => {
    try {
      await deleteUsers(username);
      setUpdate((prev) => !prev);
    } catch (error) {
      console.error(
        `Error deleting User with ID ${username}:`,
        error.response.data
      );
      alert(`Failed to delete User: ${error.response.data.title}`);
    }
  };
  const closeAddUserModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteUserHandler = (username) => {
    if (
      window.confirm(
        `Are you sure you want to delete User with ID ${username}?`
      )
    ) {
      deleteExistingUser(username);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterUsers(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    setFilteredUsers(searchTerm, value);
  };

  const filterUsers = (searchTerm, column) => {
    const filtered = Users.filter((user) =>
      user[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddUserModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus />
            <span>User</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {Users.length > 0 ? (
                Object.keys(Users[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <UserTable
          Users={filteredUsers}
          openEditModal={openEditUserModal}
          deleteUser={deleteUserHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddUserModal}
        contentLabel={editMode ? "Edit User" : "Add User"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit User" : "Add User"}
        </h2>
        <UserForm
          formValues={newUser}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingUser : addNewUser}
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddUserModal}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddUser;
