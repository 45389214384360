import { memo } from "react";
import TinyBarChart from "../../../../components/TinyChart";
import { Counter } from "../Counter";

const AwardGainedParticipantCard = memo(({ barData, filterParticipants, participantsAwards, loading }) => {
  return (
    <div
      className="p-3 bg-white rounded-lg shadow-lg flex flex-col"
      style={{
        boxShadow:
          "0 4px 6px -1px rgba(59, 130, 246, 0.1), 0 2px 4px -1px rgba(59, 130, 246, 0.06)",
      }}>
      <h2 className="font-semibold text-xl pb-4 text-main">
        Award Gained Participants
      </h2>

      <div className="flex p-3 items-center justify-between w-full rounded-md">
        {loading ? (
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
          </div>
        ) : (
          <Counter filterParticipants={filterParticipants} participantsAwards={participantsAwards} />
        )}
      </div>

      <div>
        {loading ? (
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="h-24 bg-gray-300 rounded w-full"></div>
          </div>
        ) : (
          <TinyBarChart data={barData} />
        )}
      </div>
    </div>
  );
});

export default AwardGainedParticipantCard;
