import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import {customStyles} from "../../styles/customStyles"
import ProjectStatusForm from '../../components/forms/projectStatusF';
import ProjectStatusTable from '../../components/tables/projectStatusT';
import { addProjectStatus, getProjectStatusById, updateProjectStatus, deleteProjectStatus, getProjectStatuses } from '../../services/projectStatusS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddProjectStatus = () => {
  const [projectstatuses, setProjectStatuses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProjectStatus, setNewProjectStatus] = useState({
    ProjectStatusID: '',
    StatusName: '',
    Notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredProjectStatus, setFilteredProjectStatus] = useState(projectstatuses);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedProjectStatusID, setSelectedProjectStatusID] = useState(null);

  useEffect(() => {
    const fetchProjectStatuses = async () => {
      try {
        const fetchedProjectStatuses = await getProjectStatuses();
        setProjectStatuses(fetchedProjectStatuses);
        setFilteredProjectStatus(fetchedProjectStatuses); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching project statuses:', error);
      }
    };

    fetchProjectStatuses();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProjectStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewProjectStatus = async () => {
    try {
      const statusPayload = { ...newProjectStatus };
      console.log('New project status payload:', statusPayload);
      const addedStatus = await addProjectStatus(statusPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      if (error.response) {
        console.error('Error adding project status:', error.response.data);
        setErrors(error.response.data.errors || {});
        alert(`Failed to add project status: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
      } else {
        console.error('Error adding project status:', error.message);
        alert('An unexpected error occurred.');
      }
    }
  };

  const openProjectStatusModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewProjectStatus({
      ProjectStatusID: '',
      StatusName: '',
      Notes: '',
    });
  };

  const openEditProjectStatusModal = async (ProjectStatusID) => {
    try {
      console.log(ProjectStatusID)
        const fetchedStatus = await getProjectStatusById(ProjectStatusID);
        setEditMode(true);
        setIsModalOpen(true);
        setSelectedProjectStatusID(ProjectStatusID);
        setNewProjectStatus({
            ...fetchedStatus,
        });
    } catch (error) {
        console.error(`Error fetching project status with ID ${ProjectStatusID}:`, error.response ? error.response.data : error.message);
    }
};

  const updateExistingProjectStatus = async () => {
    try {
      const statusPayload = { ...newProjectStatus };
      console.log('Updated project status payload:', statusPayload);
      const updatedStatus = await updateProjectStatus(selectedProjectStatusID, statusPayload);
      setProjectStatuses((prev) => prev.map(status => (status.ProjectStatusID === selectedProjectStatusID ? updatedStatus : status)));
      setIsModalOpen(false);
    } catch (error) {
      if (error.response) {
        console.error(`Error updating project status with ID ${selectedProjectStatusID}:`, error.response.data);
        setErrors(error.response.data.errors || {});
        alert(`Failed to update project status: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
      } else {
        console.error(`Error updating project status with ID ${selectedProjectStatusID}:`, error.message);
        alert('An unexpected error occurred.');
      }
    }
  };

  const deleteExistingProjectStatus = async (ProjectStatusID) => {
    try {
        await deleteProjectStatus(ProjectStatusID);
        setProjectStatuses((prev) => prev.filter(status => status.ProjectStatusID !== ProjectStatusID));
    } catch (error) {
        console.error(`Error deleting project status with ID ${ProjectStatusID}:`, error.response ? error.response.data : error.message);
        alert(`Failed to delete project status: ${error.response ? error.response.data.title : error.message}`);
    }
};

  const closeAddProjectStatusModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteProjectStatusHandler = (ProjectStatusID) => {
    if (window.confirm(`Are you sure you want to delete project status with ID ${ProjectStatusID}?`)) {
        deleteExistingProjectStatus(ProjectStatusID);
    }
};
//Search
const handleSearchChange = (event) => {
  const value = event.target.value;
  setSearchTerm(value);
  filterProjectStatuses(value, selectedColumn);
};
const handleFilterParameterChange = (event) => {
  const value = event.target.value;
  setSelectedColumn(value);
  filterProjectStatuses(searchTerm, value);
};

const filterProjectStatuses = (searchTerm, column) => {
  const filtered = projectstatuses.filter((projectStatus) =>
    projectStatus[column]
      .toString()
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  setFilteredProjectStatus(filtered);
};


  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Project Status</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openProjectStatusModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Add Status
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {projectstatuses.length > 0 ? (
                Object.keys(projectstatuses[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <ProjectStatusTable
        projectstatuses={filteredProjectStatus}
        openEditModal={openEditProjectStatusModal}
        deleteStatus={deleteProjectStatusHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddProjectStatusModal}
      contentLabel={editMode ? "Edit Project Status" : "Add Project Status"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Project Status" : "Add Project Status"}
      </h2>
      <ProjectStatusForm
        formValues={newProjectStatus}
        handleInputChange={handleInputChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingProjectStatus : addNewProjectStatus}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddProjectStatusModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddProjectStatus;
