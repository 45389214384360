import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import PartnershipForm from '../../components/forms/partnershipF';
import PartnershipTable from '../../components/tables/partnershipT';
import { addPartnership as addPartnershipService, getPartnershipById, updatePartnership, deletePartnership, getPartnership } from '../../services/partnershipS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddPartnership = () => {
  const [partnerships, setPartnerships] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPartnership, setNewPartnership] = useState({ 
      partnerID: '',
      partnerName: '',
      partnerEmail: '',
      phoneNo: '',
      partnerType: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredPartnerships, setFilteredPartnerships] = useState(partnerships);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedPartnershipId, setSelectedPartnershipId] = useState(null);

  useEffect(() => {
    const fetchPartnerships = async () => {
      try {
        const fetchedPartnerships = await getPartnership();
        setPartnerships(fetchedPartnerships);
        setFilteredPartnerships(fetchedPartnerships); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching partnerships:', error.response.data);
      }
    };

    fetchPartnerships();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPartnership((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewPartnership = async () => {
    try {
      const partnershipPayload = { ...newPartnership };
      await addPartnershipService(partnershipPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error('Error adding partnership:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add partnership: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddPartnershipModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewPartnership({
      partnerID: '',
      partnerName: '',
      partnerEmail: '',
      phoneNo: '',
      partnerType: '',
    });
  };

  const openEditPartnershipModal = async (partnerID) => {
    try {
      const fetchedPartnership = await getPartnershipById(partnerID);
    setUpdate(prev=>!prev)
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedPartnershipId(partnerID);
      setNewPartnership({
        ...fetchedPartnership,
      });
    } catch (error) {
      console.error(`Error fetching partnership with ID ${partnerID}:`, error.response.data);
    }
  };

  const updateExistingPartnership = async () => {
    try {
      const partnershipPayload = { ...newPartnership };

      console.log('Updated Partnership Payload:', partnershipPayload);

      await updatePartnership(selectedPartnershipId, partnershipPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating partnership with ID ${selectedPartnershipId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update partnership: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingPartnership = async (partnerID) => {
    try {
      await deletePartnership(partnerID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting partnership with ID ${partnerID}:`, error.response.data);
      alert(`Failed to delete partnership: ${error.response.data.title}`);
    }
  };

  const closeAddPartnershipModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deletePartnershipHandler = (partnerID) => {
    if (window.confirm(`Are you sure you want to delete partnership with ID ${partnerID}?`)) {
      deleteExistingPartnership(partnerID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterPartnerships(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterPartnerships(searchTerm, value);
    };
  
    const filterPartnerships = (searchTerm, column) => {
      const filtered = partnerships.filter((partnership) =>
        partnership[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredPartnerships(filtered);
    };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Partnerships</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddPartnershipModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Partnership
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {partnerships.length > 0 ? (
                  Object.keys(partnerships[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <PartnershipTable
          partnerships={filteredPartnerships}
          openEditModal={openEditPartnershipModal}
          deletePartnership={deletePartnershipHandler}
        />
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddPartnershipModal}
        contentLabel={editMode ? "Edit Partnership" : "Add Partnership"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Partnership" : "Add Partnership"}
        </h2>
        <PartnershipForm
          formValues={newPartnership}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingPartnership : addNewPartnership}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddPartnershipModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  
  );
};

export default AddPartnership;
