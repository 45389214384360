import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from '../../utils/splitWordFunc';
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { customStyles } from "../../styles/customStyles";
import LevelsForm from "../../components/forms/participantLevelsF";
import LevelsTable from "../../components/tables/participantLevelT";
import {
  addLevels as addLevelsService,
  getLevelsById,
  updateLevels,
  deleteLevels,
  getLevels,
} from "../../services/participantLevelsS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddLevels = () => {
  const [levels, setLevels] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("levelName");
  const [searchTerm, setSearchTerm] = useState('');
const [update,setUpdate] = useState(true)
  const [filteredLevels, setFilteredLevels] = useState(levels);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLevels, setNewLevels] = useState({
    levelID: "",
    levelName: "",
    duration: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedLevelsID, setSelectedLevelsID] = useState(null);

  useEffect(() => {
    const fetchedlevels = async () => {
      try {
        const fetchedLevels = await getLevels();
        setLevels(fetchedLevels);
        setFilteredLevels(fetchedLevels)
      } catch (error) {
        console.error("Error fetching levels:", error.response.data);
      }
    };

    fetchedlevels();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLevels((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewLevels((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewLevels = async () => {
    try {
      const levelsPayload = {
        levelID: String(newLevels.levelID), // Ensure LevelsID is a string
        levelName: newLevels.levelName,
        duration: newLevels.duration,
        notes: newLevels.notes,
      };

      console.log("New levels Payload:", levelsPayload);
      const addedlevels = await addLevelsService(levelsPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding levels:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add levels: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddLevelsModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewLevels({
      levelID: "",
      levelName: "",
      duration: "",
      notes: "",
    });
  };

  const openEditLevelsModal = async (levels) => {
    try {
      const fetchedLevels = await getLevelsById(String(levels.levelID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedLevelsID(levels.levelID);
      setNewLevels({
        ...fetchedLevels,
      });
    } catch (error) {
      console.error(
        `Error fetching levels with ID ${levels.levelID}:`,
        error.response.data
      );
    }
  };

  const updateExistingLevels = async () => {
    try {
      const levelsPayload = { ...newLevels };

      console.log("Updated levels Payload:", levelsPayload);

      const updatedLevels = await updateLevels(selectedLevelsID, levelsPayload);
    setUpdate(update=>!update)
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating levels with ID ${selectedLevelsID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update levels: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingLevels = async (levelID) => {
    try {
      await deleteLevels(levelID);
      setLevels((prev) => prev.filter((inst) => inst.levelID !== levelID));
    } catch (error) {
      console.error(
        `Error deleting levels with ID ${levelID}:`,
        error.response.data
      );
      alert(`Failed to delete levels: ${error.response.data.title}`);
    }
  };

  const closeAddLevelsModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteLevelsHandler = (levelID) => {
    if (
      window.confirm(
        `Are you sure you want to delete levels with ID ${levelID}?`
      )
    ) {
      deleteExistingLevels(levelID);
    }
  };


   //Search 
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterLevels(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterLevels(searchTerm, value);
  };

  const filterLevels = (searchTerm, column) => {
    const filtered = levels.filter(level => 
      level[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLevels(filtered);
  };
  
  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Levels</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
          <button
            onClick={openAddLevelsModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Levels
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {levels.length > 0 ? (
                  Object.keys(levels[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search levels..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="mt-8">
          <LevelsTable
            levels={filteredLevels}
            openEditModal={openEditLevelsModal}
            deleteLevels={deleteLevelsHandler}
          />
        </div>
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddLevelsModal}
        contentLabel={editMode ? "Edit Levels" : "Add Levels"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Levels" : "Add Levels"}
        </h2>
        <LevelsForm
          formValues={newLevels}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingLevels : addNewLevels}
            className="bg-indigo-600 px-5 text-white py-2 rounded mr-2 hover:bg-indigo-700 transition-all duration-200"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddLevelsModal}
            className="border border-indigo-600 px-5 text-indigo-600 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>   
  );
};

export default AddLevels;
