import apiClient from '../apiClient';
import { formatDate } from './formatDate';

// Endpoint for fetching institution data
const AWARDCENTERS_API_URL = '/api/AwardCenter';

// Function to fetch institution data
export const fetchInstitutionData = async () => {
  try {
    const response = await apiClient.get(AWARDCENTERS_API_URL);
    
    // Check if the response data is an array
    if (!Array.isArray(response.data)) {
      console.error('Expected an array of data, but received:', response.data);
      return [];
    }

    // Format dates in the response data
    const formattedData = response.data.map(item => ({
      ...item,
      licenseStartDate: formatDate(item.licenseStartDate),
      licenseEndDate: formatDate(item.licenseEndDate),
    }));

    return formattedData;
  } catch (error) {
    console.error('Error fetching institution data:', error);
    throw error;
  }
};

// Headers configuration for the institution report
export const institutionHeaders = [
  { Header: 'Center No', accessor: 'institutionID' },
  { Header: 'Name', accessor: 'institutionName' },
  { Header: 'Stage', accessor: 'stage' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Email', accessor: 'institutionEmail' },
  { Header: 'Contact', accessor: 'institutionContact' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Sub-County', accessor: 'subCounty' },
  { Header: 'Contact Person', accessor: 'contactPerson' },
  { Header: 'Contact Number', accessor: 'contactNumber' },
  { Header: 'License Startdate', accessor: 'licenseStartDate' },
  { Header: 'License Enddate', accessor: 'licenseEndDate' },
  { Header: 'Award Centre Type', accessor: 'awardCType' },
  { Header: 'Source', accessor: 'source' },
  { Header: 'Marginalised', accessor: 'marginalised' },
  { Header: 'Notes', accessor: 'notes' },
];

