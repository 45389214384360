import React, { useState, useEffect, useMemo } from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
import { getUsersByUsername } from '../../services/usersS';

const columns = [
  { Header: 'Center', accessor: 'awardCentre' },
  { Header: 'Award Leader', accessor: 'awardLeader' },
  { Header: 'Activity Name', accessor: 'activityName' },
  { Header: 'Region', accessor: 'region' },
  { Header: 'No. of Participants', accessor: 'participantsNo' },
  { Header: 'Upload Report', accessor: 'uploadReport' },
  { Header: 'Notes', accessor: 'notes' },
];

const AjReportingTable = ({ AjReporting, openEditModal, deleteAjReporting }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;

  const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName);
        } else {
          console.warn("No institution name found in user data");
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserInstitution();
  }, []);

  const filteredAjReporting = useMemo(() => {
    if (!loggedInUser || !AjReporting) return [];
    if (loggedInUser.role.name === "Award Leader" && userInstitution) {
      return AjReporting.filter(report => report.awardCentre === userInstitution);
    }
    return AjReporting; // Admin sees all reports
  }, [loggedInUser, AjReporting, userInstitution]);

  const renderRowActions = ({id}) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>openEditModal(id)}
      onDelete={()=>deleteAjReporting(id)}
    />
    </div>
  );

  return (
    <Table
      columns={columns}
      data={filteredAjReporting}
      renderRowActions={renderRowActions}
    />
  );
};

export default AjReportingTable;
