// src/utils/logger.js
import { logChange } from '../services/loggingService';
import axios from 'axios';

let user = localStorage.getItem("user")


const createLogData = async(actionType, entityName, description) =>{
  const ipAddress = await getIpAddress();
  const eatTime = new Date(new Date().getTime() + 3 * 60 * 60 * 1000).toISOString();
 return {
  id: 0, // Adjust as needed, or handle ID generation
  username: JSON.parse(user)?.user.username, // Fetch current user data from context or state
  actionType,
  entityName,
  ipAddress: `${ipAddress}`, // Ideally, get real IP address
  browser: navigator.userAgent,
  timeStamp: eatTime,
  description,
}} ;

export const logAction = async (actionType, entityName, description) => {
  const logData = await createLogData(actionType, entityName, description);

  await logChange(logData);
};



const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip; // Return the public IP address
  } catch (error) {
    console.error("Could not fetch IP address", error);
    return 'IP_NOT_FOUND'; // Return a fallback value in case of an error
  }
};