import * as XLSX from 'xlsx';

const companyDetails = {
  name: "President's Award - Kenya",
  address: "15 Elgon Road, opposite the Kadhi's Court in Upper Hill, Nairobi",
  email: "info@presidentsaward.or.ke",
  phone: "0722 714 122, 0787 419 325",
};

export const formatExcelReport = (title, data, headers) => {
  // Create a new workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([]);

  // Add the company details and title to the sheet
  const companyInfo = [
    [{ v: companyDetails.name, t: 's' }],
    [{ v: companyDetails.address, t: 's' }],
    [{ v: `Email: ${companyDetails.email}`, t: 's' }],
    [{ v: `Phone: ${companyDetails.phone}`, t: 's' }],
    [], // Empty row for spacing
    [{ v: title, t: 's' }],
    [], // Empty row before table headers
  ];

  XLSX.utils.sheet_add_aoa(ws, companyInfo, { origin: 'A1' });

  // Determine the number of columns to merge (based on headers length)
  const totalColumns = headers.length;

  // Merge cells for company details and title
  ws['!merges'] = [
    { s: { c: 0, r: 0 }, e: { c: totalColumns - 1, r: 0 } }, // Company name
    { s: { c: 0, r: 1 }, e: { c: totalColumns - 1, r: 1 } }, // Address
    { s: { c: 0, r: 2 }, e: { c: totalColumns - 1, r: 2 } }, // Email
    { s: { c: 0, r: 3 }, e: { c: totalColumns - 1, r: 3 } }, // Phone
    { s: { c: 0, r: 5 }, e: { c: totalColumns - 1, r: 5 } }, // Title
  ];

  // Add the headers to the sheet using the provided headers
  const headerRow = headers.map(header => header.Header);
  XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A8' });

  // Add the table data
  const tableData = data.map(row => headers.map(header => row[header.accessor]));
  XLSX.utils.sheet_add_aoa(ws, tableData, { origin: 'A9' });

  // Adjust column widths
  ws['!cols'] = headers.map(() => ({ wch: 20 })); // Set width to 20 characters for all columns

  // Apply styles
  for (let R = 0; R < companyInfo.length + tableData.length + 2; ++R) {
    for (let C = 0; C < totalColumns; ++C) {
      const cell_address = XLSX.utils.encode_cell({ r: R, c: C });
      if (!ws[cell_address]) continue;
      
      const cell = ws[cell_address];
      cell.s = {
        font: { name: "Arial", sz: 11 },
        alignment: { horizontal: "center", vertical: "center" },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" }
        }
      };

      // Make company details bold and centered
      if (R >= 0 && R <= 3) {
        cell.s.font.bold = true;
        cell.s.font.sz = 12;
      }

      // Special styling for title
      if (R === 5) {
        cell.s.font.bold = true;
        cell.s.font.sz = 14;
      }

      // Header row styling (bold and centered)
      if (R === 7) {
        cell.s.font.bold = true;
        cell.s.font.sz = 12;
        cell.s.fill = { fgColor: { rgb: "4472C4" } };
        cell.s.font.color = { rgb: "FFFFFF" };
      }
    }
  }

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Report');

  // Generate the file
  XLSX.writeFile(wb, `${title}.xlsx`);
};