import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaFilter } from "react-icons/fa";
import  {customStyles} from "../../styles/customStyles"
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingLevelForm from '../../components/forms/trainingLevelF';
import TrainingLevelTable from '../../components/tables/trainingLevelT';
import { addTrainingLevel as addTrainingLevelService, getTrainingLevelById, updateTrainingLevel, deleteTrainingLevel, getTrainingLevel } from '../../services/trainingLevelS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddTrainingLevel = () => {
  const [traininglevels, setTrainingLevel] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTrainingLevel, setNewTrainingLevel] = useState({
    trainingLevelID: '',
    levelName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("levelName");
  const [filteredTrainingLevels, setFilteredTrainingLevels] = useState(traininglevels);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingLevelID, setSelectedTrainingLevelID] = useState(null);

  useEffect(() => {
    const fetchedtrainingLevel = async () => {
      try {
        const fetchedTrainingLevel = await getTrainingLevel();
        setTrainingLevel(fetchedTrainingLevel);
        setFilteredTrainingLevels(fetchedTrainingLevel);
      } catch (error) {
        console.error('Error fetching trainingLevel:', error.response.data);
      }
    };

    fetchedtrainingLevel();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingLevel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingLevel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTrainingLevel = async () => {
    try {
      const trainingLevelPayload = {
        trainingLevelID: String(newTrainingLevel.trainingLevelID), // Ensure TrainingLevelID is a string
        levelName: newTrainingLevel.levelName,
        notes: newTrainingLevel.notes,
      };
       await addTrainingLevelService(trainingLevelPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      setErrors(error.response.data.errors || {});
      alert(`Failed to add trainingLevel: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddTrainingLevelModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTrainingLevel({
      trainingLevelID: '',
      levelName: '',
      notes: '',
    });
  };

  const openEditTrainingLevelModal = async (trainingLevelID) => {
    try {
      const fetchedTrainingLevel = await getTrainingLevelById(String(trainingLevelID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingLevelID(trainingLevelID);
      setNewTrainingLevel({
        ...fetchedTrainingLevel,
      });
    } catch (error) {
      console.error(`Error fetching trainingLevel with ID ${trainingLevelID}:`, error.response.data);
    }
  };

  const updateExistingTrainingLevel = async () => {
    try {
      const trainingLevelPayload = { ...newTrainingLevel };

      await updateTrainingLevel(selectedTrainingLevelID, trainingLevelPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating trainingLevel with ID ${selectedTrainingLevelID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update trainingLevel: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingTrainingLevel = async (trainingLevelID) => {
    try {
      await deleteTrainingLevel(trainingLevelID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      console.error(`Error deleting trainingLevel with ID ${trainingLevelID}:`, error.response.data);
      alert(`Failed to delete trainingLevel: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingLevelModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingLevelHandler = (trainingLevelID) => {
    if (window.confirm(`Are you sure you want to delete trainingLevel with ID ${trainingLevelID}?`)) {
      deleteExistingTrainingLevel(trainingLevelID);
    }
  };

   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTrainingLevels(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTrainingLevels(searchTerm, value);
  };

  const filterTrainingLevels = (searchTerm, column) => {
    const filtered = traininglevels.filter((participant) =>
      participant[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTrainingLevels(filtered);
  };

  return (
<Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Training Level</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddTrainingLevelModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          <span>Add Level</span>
        </button>

        <form className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {traininglevels.length > 0 ? (
                Object.keys(traininglevels[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </form>
      </div>

      <TrainingLevelTable
        traininglevels={filteredTrainingLevels}
        openEditModal={openEditTrainingLevelModal}
        deleteTrainingLevel={deleteTrainingLevelHandler}
      />
    </div>

    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={closeAddTrainingLevelModal}
      contentLabel={editMode ? "Edit Training Level" : "Add Training Level"}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Training Level" : "Add Training Level"}
      </h2>
      <TrainingLevelForm
        formValues={newTrainingLevel}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingTrainingLevel : addNewTrainingLevel}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddTrainingLevelModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  
  );
};

export default AddTrainingLevel;
