import apiClient from './apiClient';
import { logAction } from '../utils/logger';
const addHelperTypes = async (levelData) => {
  try {
    const response = await apiClient.post('/api/HelperTypes', levelData);
    if(response){
      logAction("Created Helper Types","Helper Types",`Created the following Helper Types ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error('Error in addHelperTypes:', error.response.data);
    throw error;
  }
};

const getHelperTypesById = async (typeId) => {
  try {
    const response = await apiClient.get(`/api/HelperTypes/${typeId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching HelperTypes with ID ${typeId}:`, error.response.data);
    throw error;
  }
};

const updateHelperTypes = async (typeId, updatedData) => {
  try {
    const response = await apiClient.put(`/api/HelperTypes/${typeId}`, updatedData);
    if(response){
      logAction("Updated Helper Types","Helper Types",`Updated the following Helper Types ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    console.error(`Error updating HelperTypes with ID ${typeId}:`, error.response.data);
    throw error;
  }
};

const deleteHelperTypes = async (typeId) => {
  try {
    const response = await apiClient.delete(`/api/HelperTypes/${typeId}`);
    if(response){
      logAction("Deleted Helper Types","Helper Types",`Deleted the following Helper Types ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    console.error(`Error deleting HelperTypes with ID ${typeId}:`, error.response.data);
    throw error;
  }
};

const getHelperTypes = async () => {
  try {
    const response = await apiClient.get('/api/HelperTypes');
    return response.data;
  } catch (error) {
    console.error('Error fetching HelperTypes:', error.response.data);
    throw error;
  }
};

export { addHelperTypes, getHelperTypesById, updateHelperTypes, deleteHelperTypes, getHelperTypes };
