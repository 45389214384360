import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus, FaFilter } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { customStyles } from "../../styles/customStyles";
import AjApprovalForm from "../../components/forms/ajApprovalF";
import AjApprovalTable from "../../components/tables/ajApprovalT";
import AjApprovalModal from "../activities/AjApprovalModal";
import Layout from "../../components/Layout/layout";
import {formatDateFromFormValues} from "../../utils/splitWordFunc"
import {
  getAjApproval,
  getAjApprovalById,
} from "../../services/ajsApprovalS";

Modal.setAppElement("#root");

// Helper function to format date to yyyy-MM-dd
const formatDate = (date) => {
  const d = new Date(date);
  return d.toISOString().split('T')[0];
};

// Helper function to validate date string
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

const INITIAL_FORM_STATE = {
  awardCentre: "",
  awardLeader: "",
  region: "",
  activityName: "",
  applyDate: formatDate(new Date()), 
  activityDate: formatDate(new Date()),  
  assessors: "",
  assessors2: "",
  assessors3: "",
  participantsNo: "",
  uploadForm: null,
  approval: false,
  consent: false,
  notes: "",
};

const AddAjApproval = () => {
  const [approvals, setApprovals] = useState([]);
  const [filteredApprovals, setFilteredApprovals] = useState([]);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [modalStates, setModalStates] = useState({
    form: false,
    approval: false
  });

  useEffect(() => {
    fetchApprovals();
  }, []);

  const fetchApprovals = async () => {
    try {
      const data = await getAjApproval();
      setApprovals(data);
      setFilteredApprovals(data);
    } catch (error) {
      console.error("Error fetching approvals:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.uploadForm) {
      newErrors.uploadForm = "The UploadForm field is required.";
    }
    
    if (!formData.activityDate) {
      newErrors.activityDate = "Activity date is required.";
    }
    
    // Add other validations as needed
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    const submitFormData = new FormData();

    // Properly format and append each field
    submitFormData.append("AwardCentre", formData.awardCentre);
    submitFormData.append("AwardLeader", formData.awardLeader);
    submitFormData.append("Region", formData.region);
    submitFormData.append("ActivityName", formData.activityName);
    submitFormData.append("ApplyDate", formData.applyDate);
    submitFormData.append("ActivityDate", formData.activityDate);
    submitFormData.append("Assessors", formData.assessors);
    submitFormData.append("Assessors2", formData.assessors2);
    submitFormData.append("Assessors3", formData.assessors3);
    submitFormData.append("ParticipantsNo", formData.participantsNo);
    submitFormData.append("Notes", formData.notes);
    submitFormData.append("Approval", formData.approval.toString());
    submitFormData.append("Consent", formData.consent.toString());
    
    // Handle file upload
    if (formData.uploadForm) {
      submitFormData.append("UploadForm", formData.uploadForm);
    }

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval${editMode ? `/${selectedId}` : ''}`;
      const method = editMode ? 'PUT' : 'POST';
      
      const response = await fetch(url, { 
        method, 
        body: submitFormData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData));
      }
      
      closeModal('form');
      fetchApprovals();
      setErrors({});
    } catch (error) {
      console.error("Form submission error:", error);
      try {
        const errorData = JSON.parse(error.message);
        setErrors(errorData.errors || {});
      } catch {
        setErrors({ general: "An error occurred while submitting the form" });
      }
    }
  };

  const handleApprovalAction = async (id, action, assessors = "", notes = "") => {

    try {
      const formData = new FormData();
      formData.append("Approval", action === 'approve' ? "true" : "false");
      
      if (action === 'approve' && assessors) {
        formData.append("Assessors", JSON.stringify(assessors));
      } else if (action === 'reject' && notes) {
        formData.append("Notes", notes);
      }
   
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval/${id}`,
        { method: "PUT", body: formData }
      );
  
      if (!response.ok) throw new Error(await response.text());
  
      fetchApprovals();
      closeModal('approval');
    } catch (error) {
      console.error("Approval action error:", error);
    }
  };
  

  const handleEdit = async (id) => {
    try {
      const data = await getAjApprovalById(id);
      setFormData({
        ...INITIAL_FORM_STATE,
        ...data,
        activityDate: data.activityDate ? new Date(data.activityDate).toISOString().split('T')[0] : '',
        consent: Boolean(data.consent),
        approval: Boolean(data.approval)
      });
      setSelectedId(id);
      setEditMode(true);
      openModal('form');
    } catch (error) {
      console.error("Error fetching approval:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData(prev => ({
        ...prev,
        [name]: files[0]
      }));
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this approval?")) return;
    
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ActivityApproval/${id}`, {
        method: 'DELETE'
      });
      fetchApprovals();
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = approvals.filter(approval =>
      approval[selectedColumn]?.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredApprovals(filtered);
  };

  const openModal = (type) => setModalStates(prev => ({ ...prev, [type]: true }));
  const closeModal = (type) => {
    setModalStates(prev => ({ ...prev, [type]: false }));
    if (type === 'form') {
      setFormData(INITIAL_FORM_STATE);
      setEditMode(false);
      setErrors({});
    }
  };

  return (
    <Layout>
      <div className="bg-white rounded-lg shadow-lg">
        {/* Header section */}
        <div className="px-6 py-4 border-b border-gray-200">
          <h1 className="text-2xl font-semibold text-gray-800">Adventurous Journey Approval</h1>
        </div>

        <div className="p-6">
          {/* Action buttons and search section */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-6">
            <button
              onClick={() => openModal('form')}
              className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-all"
            >
              <FaPlus className="mr-2" /> AJ Approval
            </button>

            <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
              <select
                className="flex-1 p-2 border rounded-md"
                value={selectedColumn}
                onChange={(e) => setSelectedColumn(e.target.value)}
              >
                {Object.keys(approvals[0] || {}).map(key => (
                  <option key={key} value={key}>{splitWordFunc(key)}</option>
                ))}
              </select>

              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Search approvals..."
                  onChange={(e) => handleSearch(e.target.value)}
                  className="w-full p-2 border rounded-md pl-10"
                />
                <FiSearch className="absolute left-3 top-3 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Table */}
          <AjApprovalTable
            AjApproval={filteredApprovals}
            openEditModal={handleEdit}
            deleteAjApproval={handleDelete}
            openApprovalModal={(app) => {
              setSelectedApplication(app);
              openModal('approval');
            }}
          />
        </div>

        {/* Form Modal */}
        <Modal
          isOpen={modalStates.form}
          onRequestClose={() => closeModal('form')}
          style={customStyles}
        >
          <h2 className="text-xl font-semibold mb-4">
            {editMode ? "Edit" : "Add"} Approval
          </h2>
          <AjApprovalForm
            formValues={formData}
            setFormValues={setFormData}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            handleDateChange={handleInputChange}
            errors={errors}
          />
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={handleFormSubmit}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              {editMode ? "Update" : "Save"}
            </button>
            <button
              onClick={() => closeModal('form')}
              className="px-4 py-2 border rounded-md hover:bg-gray-100"
            >
              Cancel
            </button>
          </div>
        </Modal>

        {/* Approval Modal */}
        <AjApprovalModal
          isOpen={modalStates.approval}
          onClose={() => closeModal('approval')}
          application={selectedApplication}
          onApprove={(id, assessors) => handleApprovalAction(id, 'approve', assessors)}
          onReject={(id) => handleApprovalAction(id, 'reject')}
        />
      </div>
    </Layout>
  );
};

export default AddAjApproval;